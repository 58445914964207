import throttle from 'lodash/throttle'

var StickyNav = (() => {
  // Dom elements ref vars
  let navigation, articleContent, articleInfo, stickyNavContainer, navIcons, navLinks, shareLinks, anchor
  //state
  let navFixed = false
  let titleVisible = false
  /**
   * Updates the nav state.
   *
   * @param {bool} fixed true if the nav needs to be sticky
   */
  const setNavFixed = fixed => {
    if (fixed && !navFixed) {
      navFixed = true
      stickyNavContainer.classList.add('is-sticky')
      anchor.style.height = `${stickyNavContainer.offsetHeight}px`
      stickyNavContainer.querySelectorAll('.fade-in-when-sticky').forEach(b => {
        b.classList.replace('hidden', 'block')
      })
    } else if (!fixed && navFixed) {
      navFixed = false
      stickyNavContainer.classList.remove('is-sticky')
      anchor.style.height = '0px'
      stickyNavContainer.querySelectorAll('.fade-in-when-sticky').forEach(b => {
        b.classList.replace('block', 'hidden')
      })
    }
  }

  /**
   * Handle article title reveal
   * @param {bool} visible true if the nav needs to be reveal
   */
  const setTitleVisible = visible => {
    if (visible && !titleVisible) {
      titleVisible = true
      navLinks.classList.replace('block', 'hidden')
      navIcons.classList.replace('block', 'hidden')
      articleInfo.classList.replace('hidden', 'block')
      shareLinks.classList.replace('hidden', 'flex')
    } else if (!visible && titleVisible) {
      titleVisible = false
      navLinks.classList.replace('hidden', 'block')
      navIcons.classList.replace('hidden', 'block')
      articleInfo.classList.replace('block', 'hidden')
      shareLinks.classList.replace('flex', 'hidden')
    }
  }

  /**
   * Checks, if the nav reached the top position to get sticky
   */
  const checkStickyNav = () => {
    if (anchor.getBoundingClientRect().top <= 0) {
      setNavFixed(true)
    } else {
      setNavFixed(false)
    }
  }

  /**
   * Checks, if the article title reached the top position to get visible
   */
  const setArticleTitleVisibility = () => {
    if (articleContent.getBoundingClientRect().top <= 0) {
      setTitleVisible(true)
    } else {
      setTitleVisible(false)
    }
  }

  /**
   * Bind the events for the nav
   */
  const bind = () => {
    window.addEventListener('scroll', throttle(checkStickyNav, 20))
    if (articleContent) {
      window.addEventListener('scroll', throttle(setArticleTitleVisibility, 20))
    }
  }

  /**
   * Initilize the sticky nav implementation for the dropdown
   */
  const init = () => {
    navigation = document.querySelector('#navigation')
    // Everything here is scoped to the navigation element. If it's not present,
    // we can bail.
    if (!navigation) return
    // Sticky nav implementation
    articleContent = document.querySelector('.article-content')
    articleInfo = document.querySelector('.article-info')
    stickyNavContainer = navigation.querySelector('#sticky-nav')
    navIcons = navigation.querySelector('.sticky-nav-icons')
    navLinks = navigation.querySelector('.header-nav-links')
    shareLinks = navigation.querySelector('.share-links')

    // Insert an empty anchor so we know the original location of the nav at all times
    anchor = document.createElement('div')
    stickyNavContainer.parentElement.insertBefore(anchor, stickyNavContainer)

    if (stickyNavContainer) bind()
  }

  return { init }
})()

export default StickyNav
