import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const LETeaserPage = lazyComponent(
  () => import('site/components/sponsored_content/LimitedEditions/TeaserPage'),
  <FullscreenFallback />
)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({ LETeaserPage })
