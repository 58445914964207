import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const GShock40thLanding = lazyComponent(
  () => import('site/components/sponsored_content/GShock40thLanding'),
  <FullscreenFallback />
)

WebpackerReact.setup({ GShock40thLanding })
