import { mediaChangeListener } from 'site/helpers'
import debounce from 'lodash/debounce'

/**
 * Default headers
 */
const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest'
}

var ShopCart = (() => {
  /**
   * Get the cart information.
   */
  const fetchData = async () => {
    const resp = await fetch('/api/v1/shop/cart', {
      credentials: 'same-origin',
      method: 'GET',
      headers: HEADERS
    })
    try {
      const items = await resp.json()
      if (resp.ok) {
        document.querySelectorAll('.cartCount').forEach(el => {
          el.innerHTML = items.item_count
          if (items.item_count > 0) {
            el.classList.add('visible-count')
          } else {
            el.classList.remove('visible-count')
          }
        })
      }
    } catch (e) {
      console.error('Error fetching shop cart ', e)
    }
  }

  /**
   * Prevents multiple calls to the API. It takes more than 5 
   * seconds to add a product to the cart in the shop.
   */
  const debouncedFetchData = debounce(() => {
    fetchData()
  }, 5000)

  /**
   * Bind the events for the view
   */
  const bind = () => {
    // For the mobile view
    mediaChangeListener(matchMedia('(max-width: 1024px)'), debouncedFetchData, { once: true })
    // To check if there is a new value for the cart when the page become visible
    window.addEventListener(
      'visibilitychange',
      () => document.visibilityState == 'visible' && debouncedFetchData(),
      true
    )
  }

  /**
   * Initilize the Shop Cart implementation for the editorial
   */
  const init = () => {
    bind()
    debouncedFetchData()
  }

  return { init }
})()

export default ShopCart
