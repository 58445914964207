import React from 'react'
import ReactDOM from 'react-dom'

import values from 'lodash/values'
import chunk from 'lodash/chunk'
import HomepageHeroItem from './hero/HomepageHeroItem'
import HomepageHeroHeroArticle from './hero/HomepageHeroHeroArticle'

export default class HomepageHeroModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      items: this.props.items || []
    }
  }

  componentDidMount() {
    if (!this.props.items) this.fetchItems()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.items != this.props.items) {
      this.fetchItems()
    } else {
      ReactDOM.findDOMNode(this)
        .querySelectorAll('.imgix-fluid')
        .forEach(fluidElement => {
          fluidElement.url = new imgix.URL(fluidElement.getAttribute('data-src'))
          imgix.fluid(ReactDOM.findDOMNode(this), { updateOnResizeDown: true }).updateSrc(fluidElement)
        })
    }
  }

  async fetchItems() {
    const moduleItems = values(this.props.module.data.items)

    const articleParams = moduleItems
      .filter(item => item.type === 'article')
      .map(item => `ids[]=${item.id}`)
      .join('&')

    const packageParams = moduleItems
      .filter(item => item.type === 'package')
      .map(item => `ids[]=${item.id}`)
      .join('&')

    const articles = (await this.performRequest('articles', articleParams)).articles
    const packages = packageParams ? (await this.performRequest('packages', packageParams)).packages : []

    const items = moduleItems.map(item => {
      let model
      switch (item.type) {
        case 'article':
          model = articles.find(article => item.id == article.id)
          break
        case 'package':
          model = packages.find(p => item.id == p.id)
          break
      }

      return { id: model.id, type: item.type, model }
    })

    this.setState({ items })
  }

  async performRequest(endpoint, params) {
    const resp = await fetch(`/api/v1/homepage/${endpoint}.json?${params}`, { credentials: 'same-origin' })
    return await resp.json()
  }

  getHeroArticle() {
    let item = this.state.items[0]
    return <HomepageHeroHeroArticle article={item.model} />
  }

  getItem(items) {
    return items.map((item, i) => {
      return <HomepageHeroItem key={`${item.type}-${item.id}`} item={item} />
    })
  }

  render() {
    if (!this.state.items.length) return null

    const itemsInThrees = chunk(this.state.items.slice(1), 3)

    return (
      <div className="homepage-module homepage-module--hero">
        <div className="content-wrapper small-fullbleed">
          {this.getHeroArticle()}
          {itemsInThrees.map((group, i) => (
            <div className="articles-container" key={`group-${i}`}>
              <div className="articles-scroller">{this.getItem(group)}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
