import React from 'react'

export default function SvgIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={props.className}
      alt={props.alt}
    >
      <use xlinkHref={props.id} height={props.height} width={props.width}></use>
    </svg>
  )
}