import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const BlancpainTwoSides = lazyComponent(
  () => import('site/components/sponsored_content/Blancpain/TwoSides'),
  <FullscreenFallback />
)

WebpackerReact.setup({ BlancpainTwoSides })
