import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useMediaQuery } from 'react-responsive'
import ImgixHelper from 'helpers/ImgixHelper'
import ArticleBadge from './ArticleBadge'
import i18n from 'helpers/i18n'
import { ByAuthorOrCollaborationWith } from '../ArticleBlock'
import { PlaceholderCard, PlaceholderCardList } from '../search/PlaceholderCard'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import classNames from 'classnames'

export default function HomepageLatestModule({ onAdReady, type }) {
  const isMobile = useMediaQuery({ query: `(max-width: 481px)` })
  const isTablet = useMediaQuery({ query: `(min-width: 769px)` })

  const [data, setData] = useState([])
  const [error, setError] = useState(false)

  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)

  useEffect(() => {
    if (visible && data.length === 0) {
      fetchData()
    }
  }, [visible])

  useEffect(() => {
    if (data.length > 0) {
      imgix.fluid(ReactDOM.findDOMNode(mainRef.current))
    }
  }, [data])

  const fetchData = async () => {
    const resp = await fetch('/api/v1/homepage/latest')
    if (resp.status === 200) {
      const json = await resp.json()
      setData(json.cards)
    } else {
      setError(true)
    }
  }

  if (error) return null

  return (
    <div ref={mainRef} className="homepage-module homepage-module--latest">
      <div className="content-wrapper small-fullbleed">
        <h2>
          <span>{i18n('home.index.latest')}</span>
        </h2>
        <div className="articles-grid">
          {data.length > 0 ? (
            <>
              <div className="hidden md:block md:float-right sm:pb-[30px] xl:pb-0">
                {isTablet && (
                  <div className="ad text-center hide-on-empty">
                    <div className="ad-title">ADVERTISEMENT</div>
                    <div className='htl-ad htlad-HOD_Home_RR_Pos1'></div>
                  </div>
                )}
              </div>

              {data.map((article, i) => (
                <Article
                  isMobile={isMobile}
                  key={article.id}
                  index={i}
                  article={article}
                  onAdReady={onAdReady}
                  type={type}
                />
              ))}
            </>
          ) : (
            <HomepageLatestPlaceholder />
          )}
        </div>
        <a href="/latest" className="btn">
          {i18n('home.index.view_all')}
        </a>
      </div>
    </div>
  )
}

const Article = ({ article, index, isMobile }) => {
  let classes = 'article'
  let article_hero_image

  if (index === 0) {
    classes += ' article--hero'
    let article_hero_url = new ImgixHelper(article.media.image.url, {
      blend: '#f2f2f2',
      bm: 'multiply'
    })

    article_hero_image = article_hero_url.getURL()
  } else {
    article_hero_image = new ImgixHelper(article.media.image.url).getURL()
  }

  return (
    <div className={classNames(classes, { 'clear-both': !isMobile && index == 3 })}>
      <a
        href={article.action.share_url}
        className="block"
      >
        <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={article_hero_image}>
          <ArticleBadge article={article} className="article__badge" />
        </div>

        <div className="article-info">
          <h3 className="article-title">
            <span>{article.metadata.column_name}</span>
            {article.message.title}
          </h3>
        </div>

        <div className="article-sub-info">
          <p className="article-lede" dangerouslySetInnerHTML={{ __html: article.message.text_html }} />
          <p className="article-meta">
            <ByAuthorOrCollaborationWith authors={article.authors} tags={article.metadata.tags} />
          </p>
        </div>
      </a>

      {isMobile && index === 2 && (
        <div className="my-5">
          <div className="ad mx-auto text-center mb-[45px]">
            <div className="ad-title">ADVERTISEMENT</div>
            <div className='htl-ad htlad-HOD_Home_RR_Pos1'></div>
          </div>
        </div>
      )}
    </div>
  )
}
const HomepageLatestPlaceholder = () => (
  <>
    <PlaceholderCard className="article article--hero" ratio={[16, 9]} />
    <div className="block">
      <PlaceholderCardList className="article" size={4} ratio={[16, 9]} />
    </div>
  </>
)
