import React, { useEffect, useRef, useState } from 'react'
import HomepageOurEditorsAuthor, { HomepageOurEditorsAuthorPlaceholder } from './HomepageOurEditorsAuthor'
import i18n from 'helpers/i18n'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

export default function HomepageOurEditorsModule() {
  const [data, setData] = useState([])
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)

  useEffect(() => {
    if (visible && data.length === 0) {
      fetchEditors()
    }
  }, [visible])

  const fetchEditors = async () => {
    const resp = await fetch('/api/v1/homepage/our_contributors')
    if (resp.status === 200) {
      const json = await resp.json()
      setData(json)
    }
  }

  return (
    <div ref={mainRef} className="homepage-module homepage-module--our-editors">
      <div className="content-wrapper">
        <h2>
          <span>{i18n('home.index.our_editors')}</span>
        </h2>

        <div className="authors">
          {data.length === 0
            ? [...Array(10).keys()].map(entry => <HomepageOurEditorsAuthorPlaceholder key={entry} />)
            : data.map(entry => (
                <HomepageOurEditorsAuthor key={entry.author.id} author={entry.author} article={entry.article} />
              ))}
        </div>
      </div>
    </div>
  )
}
