/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const TudorBlackBayBuyersGuide2019 = lazyComponent(
  () => import('site/components/sponsored_content/TudorBlackBayBuyersGuide2019'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  TudorBlackBayBuyersGuide2019: props => <SponConWrapper template={TudorBlackBayBuyersGuide2019} {...props} />
})
