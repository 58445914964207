import React from 'react'
import ImgixHelper from 'helpers/ImgixHelper'
import { nt } from 'helpers/i18n'

const ariaI18n = nt('home.accessibility')

export default class HomepageArticleModule extends React.Component {
  static defaultProps = {
    alignment: 'center',
    buttonText: 'Read now',
    isShop: false
  }

  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  componentDidMount() {
    imgix.fluid(this.ref.current)
  }

  componentDidUpdate() {
    imgix.fluid(this.ref.current)
  }

  render() {
    const classNames = [
      'lazyload',
      'homepage-module',
      'homepage-module--shop',
      `homepage-module--shop-${this.props.alignment}`,
      'imgix-fluid'
    ]

    const ix = new ImgixHelper(this.props.image_url)
    const ariaLabel = `${this.props.buttonText}, ${ariaI18n('new_window')}`

    // The extra <div> at the root is necessary to make imgix initialization easier.
    return (
      <div>
        <div className={classNames.join(' ')} data-src={ix.getURL()} ref={this.ref}>
          <div className="mask" />

          <div className="content-wrapper">
            <div className="inner">
              {this.props.children}
              <h3>{this.props.title}</h3>
              <p className="message">{this.props.message}</p>
              <a href={this.props.link} target="_blank" className="btn btn-secondary" aria-label={ariaLabel}>
                {this.props.buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
