import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'

import Homepage from './components/Homepage'
import NavigationIcon from './components/NavigationIcon'
import SiteHeaderMoonphase from './components/SiteHeaderMoonphase'
import SvgIcon from './components/SvgIcon'

import TallFallback from './fallbacks/TallFallback'
import ShortFallback from './fallbacks/ShortFallback'
import FullscreenFallback from './fallbacks/FullscreenFallback'

const Browse = lazyComponent(() => import('./components/Browse'), <TallFallback />)
const Comments = lazyComponent(() => import('./components/Comments'), <ShortFallback />)
const CommentCount = lazyComponent(() => import('./components/CommentCount'), <span />)
const HomepageNewsletterModule = lazyComponent(
  () => import('./components/homepage_modules/HomepageNewsletterModule'),
  <div />
)
const PodcastHomepageDropdowns = lazyComponent(() => import('./components/podcast/PodcastHomepageDropdowns'), <div />)
const PodcastFooter = lazyComponent(() => import('./components/podcast/PodcastFooter'), <div />)
const Search = lazyComponent(() => import('./components/Search'), <FullscreenFallback />)
const PackageSeries = lazyComponent(() => import('./components/PackageSeries'), <div />)
const PollContentBlock = lazyComponent(() => import('./components/PollContentBlock'), <ShortFallback />)
const PackageContainer = lazyComponent(() => import('./components/packages/PackageContainer'), <FullscreenFallback />)
const Editor = lazyComponent(() => import('./components/editor/Editor'), <TallFallback />)
const HodinkeeTen = lazyComponent(() => import('./components/hodinkee_ten/HodinkeeTen'))
const ProductCard = lazyComponent(() => import('./components/ProductCard/index'))
const WaitlistContentBlock = lazyComponent(() => import('./components/WaitlistContentBlock'), <ShortFallback />)
const ShoppableBlock = lazyComponent(() => import('./components/ShoppableStories/ShoppableBlock'))
const ShoppableImages = lazyComponent(() => import('./components/ShoppableStories/ShoppableImages'))
const RecommendationContentBlock = lazyComponent(() => import('./components/RecommendationContentBlock'))
const ShoppableProductSummary = lazyComponent(() => import('./components/ShoppableStories/ShoppableProductSummary'))
const ProductHighlight = lazyComponent(() => import('./components/ShoppableStories/ProductHighlight'))
const SlideshowImage = lazyComponent(() => import('./components/ShoppableStories/SlideshowImage'))

WebpackerReact.setup({
  Browse,
  Comments,
  CommentCount,
  Editor,
  HodinkeeTen,
  Homepage,
  HomepageNewsletterModule,
  NavigationIcon,
  PackageContainer,
  PackageSeries,
  PodcastHomepageDropdowns,
  PollContentBlock,
  PodcastFooter,
  ProductCard,
  ProductHighlight,
  RecommendationContentBlock,
  Search,
  SiteHeaderMoonphase,
  ShoppableBlock,
  ShoppableImages,
  ShoppableProductSummary,
  SlideshowImage,
  SvgIcon,
  WaitlistContentBlock
})
