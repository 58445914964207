import React from 'react'
import HomepageArticleModule from './HomepageArticleModule'
import i18n from 'helpers/i18n'
import HLogoWhite from 'images/Hodinkee_White.svg'
export default class HomepageShopModule extends React.Component {
  render() {
    return (
      <HomepageArticleModule isShop={true} buttonText={i18n('home.index.shop_now')} {...this.props}>
        <img alt="Hodinkee" className="lazyload icon shop-logo inline" data-src={HLogoWhite}/>
      </HomepageArticleModule>
    )
  }
}
