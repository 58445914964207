import Authors from './Authors'
import ImgixHelper from 'helpers/ImgixHelper'
import React, { useEffect, useRef, useState } from 'react'
import i18n from 'helpers/i18n'
import ArticleBadge from './ArticleBadge'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

export default function HomepageTopStoriesModule() {
  const [articles, setArticles] = useState([])
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)

  useEffect(() => {
    if (visible && articles.length == 0) {
      fetchArticles()
    }
  }, [visible])

  useEffect(() => {
    if (articles.length > 0 && mainRef.current) {
      imgix.fluid(mainRef.current)
    }
  }, [articles])

  const fetchArticles = async () => {
    const resp = await fetch('/api/v1/cards?sort=top_this_week&limit=5')
    if (resp.status === 200) {
      const json = await resp.json()
      setArticles(json.cards.filter(card => card.type == 'article'))
    }
  }

  const getHeroArticle = () => {
    let article = articles[0]
    const ix = new ImgixHelper(article.media.image.url)

    return (
      <div className="article article--hero">
        <a
          href={article.action.share_url}
          className="article-link"
        >
          <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}></div>
          <div className="article-info">
            <h3 className="article-title">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </h3>
            <p className="article-meta">
              <Authors authors={article.authors} />
            </p>
          </div>
        </a>
      </div>
    )
  }

  const getArticles = () => {
    return articles.slice(1).map((article, i) => {
      const ix = new ImgixHelper(article.media.image.url)
      return (
        <div className="article" key={i}>
          <a
            href={article.action.share_url}
            className="article-link"
          >
            <div className="article-hero-outer">
              <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
                <ArticleBadge article={article} className="article__badge" />
              </div>
            </div>
            <div className="article-info">
              <h3 className="article-title">
                <span>{article.metadata.column_name}</span>
                {article.message.title}
              </h3>
              <p className="article-meta">
                <Authors authors={article.authors} />
              </p>
            </div>
          </a>
        </div>
      )
    })
  }

  return (
    <div ref={mainRef} className="homepage-module homepage-module--top-stories">
      <div className="content-wrapper small-fullbleed">
        <h2>
          <span>{i18n('home.index.top_stories')}</span>
        </h2>
        {articles.length > 0 && (
          <>
            {getHeroArticle()}
            <div className="articles-grid">{getArticles()}</div>
          </>
        )}
      </div>
    </div>
  )
}
