import React, { Suspense } from 'react'

export default function lazyComponent(loadFunction, fallback = <div>Loading...</div>) {
  const Component = React.lazy(loadFunction)
  return props => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  )
}
