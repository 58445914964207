import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const TudorStyleGuide = lazyComponent(
  () => import('site/components/sponsored_content/Tudor/TudorStyleGuide'),
  <FullscreenFallback />
)

WebpackerReact.setup({ TudorStyleGuide })
