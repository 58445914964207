import React, { useEffect, useRef } from 'react'
import ImgixHelper from 'helpers/ImgixHelper'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import classNames from 'classnames'

export default function HomepageNewsletterModule({ title, image_url, first_subhead, second_subhead, alignment }) {
  const mainRef = useRef()
  const imgixInit = useRef(false)
  const [visible] = useIntersectionObserver(mainRef)
  const ix = new ImgixHelper(image_url)

  useEffect(() => {
    if (visible && !imgixInit.current) {
      mainRef.current ? imgix.fluid(mainRef.current) : imgix.fluid()
      imgixInit.current = true
    }
  }, [visible])

  return (
    <div
      ref={mainRef}
      className={classNames(
        'lazyload',
        'bg-gray-5',
        'homepage-module',
        'homepage-module--newsletter',
        'imgix-fluid',
        'imgix-fluid-bg'
      )}
      data-src={ix.getURL()}
    >
      <div className={`content-wrapper align-${alignment}`}>
        <p className="heading">{title}</p>
        <p className="subhead">{first_subhead}</p>
        <p className="subhead">{second_subhead}</p>
        <div className="form--newsletter klaviyo-form-TSwyx3" />
      </div>
    </div>
  )
}
