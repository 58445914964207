import ImgixHelper from 'helpers/ImgixHelper'
import React from 'react'
import ReactDOM from 'react-dom'
import i18n from 'helpers/i18n'
import { ByAuthorOrCollaborationWith } from '../ArticleBlock'
import { PlaceholderCard } from '../search/PlaceholderCard'
import classNames from 'classnames'


export default function HomepagePopularBrand({ brand, articles }) {
  const ix = new ImgixHelper(brand.image)

  return (
    <div className="popular-brand">
      <div
        className={classNames('popular-brand--badge', 'hide-from-desktop', 'imgix-fluid', 'imgix-fluid-bg')}
        data-src={ix.getURL()}
      >
        <div className="mask" />
        <a href={`/brands/${brand.slug}`} className="brand-page-link">
          <span>{brand.title}</span>
        </a>
      </div>
      <div className="hide-until-desktop">
        <a className="popular-brand--view-all" href={`/brands/${brand.slug}`}>
          {i18n('home.index.view_all')}
        </a>
        <p className="popular-brand--name">{brand.title}</p>
        <div className="popular-brand--articles">
          {articles.map((article, i) => (
            <div className={classNames('article', { 'article--hero': i === 0 })} key={i}>
              <a
                href={article.action.share_url}
              >
                {i === 0 && (
                  <div
                    className="article-hero-image imgix-fluid imgix-fluid-bg"
                    data-src={new ImgixHelper(article.media.image.url).getURL()}
                  ></div>
                )}
                <h3 className="article-title">
                  {article.metadata.column_name ? <span>{article.metadata.column_name}</span> : undefined}
                  {article.message.title}
                </h3>
              </a>
              {i === 0 && (
                <div className="article-meta">
                  <ByAuthorOrCollaborationWith authors={article.authors} tags={article.metadata.tags} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const HomepagePopularBrandPlaceholder = () => (
  <div className="popular-brand">
    <div className="popular-brand--badge hide-from-desktop bg-gay-5">
      <div className="mask" />
      <div className="brand-page-link">
        <div className="w-1/4 h-5 bg-gray-5 h-5" />
      </div>
    </div>
    <div className="hide-until-desktop">
      <a className="popular-brand--view-all bg-gray-5 w-1/4 h-5"></a>
      <p className="popular-brand--name bg-gray-5 w-1/4 h-5" />
      <div className="popular-brand--articles">
        <PlaceholderCard className="article article--hero" ratio={[16, 9]} />
        {[...Array(2).keys()].map(i => (
          <div key={i} className="article animate-pulse">
            <div className="h-4 w-full bg-gray-5 mt-2"></div>
            <div className="h-4 w-2/3 bg-gray-5 mt-2"></div>
          </div>
        ))}
      </div>
    </div>
  </div>
)
