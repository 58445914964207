import React, { Fragment } from 'react'
import I18n from 'i18n-js'
import camelCase from 'lodash/camelCase'
import reactStringReplace from 'react-string-replace'

const nt = scope => {
  const options = { scope }
  return (key, values = {}) => I18n.t(key, Object.assign({}, values, options))
}

const i18n = I18n.t.bind(I18n)

const T = ({ id, values }) => {
  const source = I18n.lookup(id)
  return reactStringReplace(source, I18n.placeholder, (match, i) => {
    return <Fragment key={i}>{values[camelCase(match)]}</Fragment>
  })
}

export default i18n
export { nt, T }
