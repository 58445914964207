/* eslint no-console:0 */

import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineArticle = lazyComponent(() => import('site/components/magazine/MagazineArticle'), <LazyFallback />)
const MagazineVolume6 = lazyComponent(() => import('site/components/magazine/articles/Volume6'), <LazyFallback />)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineVolume6: props => <MagazineArticle component={MagazineVolume6} {...props} />
})
