import imgix from 'imgix.js'
import './modules/SiteHeader'
import NavDropdown from './modules/NavDropdown'
import StickyNav from './modules/StickyNav'
import 'site/helpers/dropdownMenu'
import ShopCart from './modules/ShopCart'
import { domReady } from 'site/helpers'

import initializePageController from './controllers'

domReady(() => {
  initializePageController()

  NavDropdown.init()
  StickyNav.init()
  ShopCart.init()
})

imgix.onready(() => {
  imgix.fluid({
    updateOnResize: true,
    updateOnResizeDown: true,
    pixelStep: 5
  })
})
