/* eslint no-console:0 */

import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineArticle = lazyComponent(() => import('site/components/magazine/MagazineArticle'), <LazyFallback />)
const MagazineVolume2 = lazyComponent(() => import('site/components/magazine/articles/Volume2'), <LazyFallback />)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineVolume2: props => <MagazineArticle component={MagazineVolume2} {...props} />
})
