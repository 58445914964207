import React from 'react'

import HomepageHeroArticle from './HomepageHeroArticle'
import HomepageHeroPackage from './HomepageHeroPackage'
import ErrorBoundary from '../../ErrorBoundary'

export default function HomepageHeroItem({ item }) {
  switch (item.type) {
    case 'article':
      return (
        <ErrorBoundary>
          <HomepageHeroArticle article={item.model} />
        </ErrorBoundary>
      )

    case 'package':
      return (
        <ErrorBoundary>
          <HomepageHeroPackage pack={item.model} />
        </ErrorBoundary>
      )
  }
}
