/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const TagCarreraBuyersGuide = lazyComponent(
  () => import('site/components/sponsored_content/TagCarreraBuyersGuide'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  TagCarreraBuyersGuide: props => <SponConWrapper template={TagCarreraBuyersGuide} {...props} />
})
