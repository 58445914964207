import React, { useEffect, useRef, useState } from 'react'
import Hammer from 'hammerjs/hammer'
import ImgixHelper from 'helpers/ImgixHelper'
import i18n from 'helpers/i18n'
import classNames from 'classnames'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import usePrevious from '../../hooks/usePrevious'
import { useMediaQuery } from 'react-responsive'
import useWindowSize from '../../hooks/useWindowSize'

export default function HomepageEditorsPicksModule() {
  const [collection, setCollection] = useState({})
  const [articles, setArticles] = useState([])
  const [margin, setMargin] = useState(150)
  const [activeIndex, setActiveIndex] = useState(0)
  const mainRef = useRef(null)
  const articleRef = useRef(null)
  const intervalRef = useRef({ pause: null, val: null })
  const prevState = usePrevious({ collection })
  const md = useMediaQuery({ maxWidth: 768 })
  const [width] = useWindowSize()
  const [visible] = useIntersectionObserver(mainRef)

  useEffect(() => {
    if (visible && articles.length == 0) {
      fetchData()
    } else if (visible && intervalRef.current.pause) {
      // when the component is visible again we start the carousel autoplay
      autoplay()
    } else if (!visible && !intervalRef.current.pause) {
      // when the component is not visible we clear the interval
      stopAutoplay()
      // we paused the carousel for performance purposes
      intervalRef.current.pause = true
    }
    if (!prevState?.collection.id && collection.id) setModuleMinHeight()
  }, [visible])

  useEffect(() => {
    if (articles.length != 0) {
      initCurrentSlideImg()
      setModuleMinHeight()
      initHammer()
      autoplay()
    }
    //clear the interval when the component is unmounted
    return () => stopAutoplay()
  }, [articles])

  useEffect(() => {
    setModuleMinHeight()
  }, [width])

  /**
   * Is responsible for initialize the image for the current slide.
   */
  const initCurrentSlideImg = () => {
    if (articleRef.current) imgix.fluid(articleRef.current)
  }

  /**
   * Fetches the collection with the article cards to initialize
   * the slideshow.
   */
  const fetchData = async () => {
    const resp = await fetch('/api/v1/homepage/collections/editors_picks')
    if (resp.status === 200) {
      const data = await resp.json()
      setCollection(data.collection)
      setArticles(data.collection.cards || [])
    }
  }

  /**
   * Calculates the margin for the slideshow.
   */
  const setModuleMinHeight = () => {
    if (!md) {
      setMargin(150)
    } else {
      let tallestTitleHeight = 0
      articleRef.current.querySelectorAll('.article-title').forEach(title => {
        if (title.clientHeight > tallestTitleHeight) {
          tallestTitleHeight = title.clientHeight
        }
      })
      setMargin(55 + Math.ceil(tallestTitleHeight / 45) * 45)
    }
  }
  /**
   * Handles the arrows buttons click event.
   *
   * @param {Event} event
   * @param {next|prev} direction Indicates in which direction is the slideshow moving.
   */
  const onArrowClick = (event, direction) => {
    event.preventDefault()
    stopAutoplay()
    changeSlide(event, direction)
  }

  /**
   * Is responsible to move to the next/prev slide.
   *
   * @param {Event} event The current event
   * @param {next|prev} direction Indicates in which direction is the slideshow moving.
   */
  const changeSlide = (event, direction) => {
    let newIndex = 0
    if (direction === 'next') {
      if (activeIndex + 1 === articles.length) newIndex = 0
      else newIndex = Math.min(activeIndex + 1, articles.length - 1)
    } else {
      if (activeIndex === 0) newIndex = articles.length - 1
      else newIndex = Math.max(activeIndex - 1, 0)
    }
    setActiveSlide(event, newIndex)
  }
  /**
   * Clear the interval and stop the autoplay for the slideshow.
   */
  const stopAutoplay = () => {
    if (intervalRef.current) clearInterval(intervalRef.current.val)
  }
  /**
   * Inits the slideshow autoplay
   */
  const autoplay = () => {
    // we move to the next slide
    intervalRef.current.pause = false
    intervalRef.current.val = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1 < articles.length ? prevIndex + 1 : 0))
    }, 5000)
  }

  /**
   * Inits the hammer.js events for the slideshow.
   */
  const initHammer = () => {
    let ham = new Hammer(mainRef.current)
    ham.on('swipeleft swiperight', e => {
      switch (e.type) {
        case 'swipeleft':
          changeSlide(e, 'next')
          break
        case 'swiperight':
          changeSlide(e, 'prev')
          break
        default:
          break
      }
    })
  }

  /**
   * Is responsible to set a specific slide as active.
   *
   * @param {Event} event The current event
   * @param {number} index The slide position.
   */
  const setActiveSlide = (evt, index) => {
    if (evt) evt.preventDefault()
    setActiveIndex(index)
  }
  /**
   * Is responsible to set a specific slide as active when
   * the dot pagination link is clicked.
   *
   * @param {Event} event The current event
   * @param {number} index The slide position.
   */
  const onPageClick = (evt, index) => {
    setActiveSlide(evt, index)
    stopAutoplay()
  }

  return (
    <div ref={mainRef}>
      <div className="homepage-module homepage-module--editors-picks">
        <h2>
          <span>{i18n('home.index.editors_picks')}</span>
        </h2>
        <div ref={articleRef} className="articles" style={{ marginBottom: margin }}>
          {articles.length === 0 ? (
            <ArticlePlaceHolder />
          ) : (
            articles.map((article, i) => {
              const imgx = new ImgixHelper(article.media.image.url)
              return (
                <div className={classNames('article', { visible: activeIndex === i })} key={i}>
                  <div
                    className={classNames('article-hero-image', 'imgix-fluid', 'imgix-fluid-bg')}
                    data-src={imgx.getURL()}
                  />
                  <h3 className="article-title">
                    <span>{article.metadata.column_name}</span>
                    {article.message.title}
                  </h3>
                  <a
                    href={article.action.share_url}
                    title={article.message.title}
                    className="article-hero-image__link"
                  />
                </div>
              )
            })
          )}
        </div>
        <div className="pagination-dots">
          {articles.length === 0 ? (
            <a className="article-select">
              <i />
            </a>
          ) : (
            articles.map((article, i) => (
              <a
                key={i}
                href={article.action.share_url}
                title={article.message.title}
                onClick={evt => onPageClick(evt, i)}
                className={classNames('article-select', 'focusable', { active: activeIndex === i })}
              >
                <i />
              </a>
            ))
          )}
        </div>
        <div className="arrows">
          <button
            type="button"
            aria-label="Previous"
            onClick={evt => onArrowClick(evt, 'prev')}
            className="ctrl arrow-button arrow-button--prev"
          >
            <svg className="icon-arrow arrow-prev">
              <use xlinkHref="#icon-right-black" />
            </svg>
          </button>
          <button
            type="button"
            aria-label="Next"
            onClick={evt => onArrowClick(evt, 'next')}
            className="ctrl arrow-button arrow-button--next"
          >
            <svg className="icon-arrow arrow-next">
              <use xlinkHref="#icon-right-black" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

const ArticlePlaceHolder = () => (
  <div className="article visible">
    <div className="article-hero-image bg-gray-5" />
    <div className="article-title flex flex-col items-center animate-pulse">
      <div className="h-5 lg:h-10 w-2/3 bg-gray-5"></div>
      <div className="h-5 lg:h-10 w-full bg-gray-5 my-5"></div>
    </div>
  </div>
)
