import React from 'react'

import logo from '../hodinkee_ten/hodinkee-ten-logo.png'

export default class HomepageH10Module extends React.Component {
  render() {
    return (
      <div className="homepage-module homepage-module--h10">
        <div className="content-wrapper">
          <img src={logo} alt="" />
          <p>Celebrating 10 years of Hodinkee with the people who made it possible. </p>
          <a href="/events/h10" className="btn">
            View Schedule
          </a>
        </div>
      </div>
    )
  }
}
