import React, { useEffect, useRef, useState } from 'react'
import HomepageFlexModule, { HomepageFlexModulePlaceholder } from './HomepageFlexModule'
import i18n from 'helpers/i18n'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import usePrevious from '../../hooks/usePrevious'

export default function HomepageColumnModule({ column_id, ...props }) {
  const [data, setData] = useState({})
  const [error, setError] = useState(!column_id)
  const [articles, setArticles] = useState([])
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)
  const prevProps = usePrevious({ column_id })

  useEffect(() => {
    if (visible && column_id && articles.length === 0) {
      fetchData()
    }
  }, [visible])

  useEffect(() => {
    if (articles.length > 0) {
      imgix.fluid(mainRef.current)
    }
    if (articles.length != 0 && prevProps?.column_id != column_id) {
      fetchData()
    }
  }, [articles])

  const fetchData = async () => {
    const resp = await fetch(`/api/v1/homepage/column/${column_id}?limit=4`)
    if (resp.status === 200) {
      const json = await resp.json()
      setData(json.column)
      setArticles(json.articles || [])
    } else {
      setError(true)
    }
  }

  if (error) return null

  return (
    <div ref={mainRef} className="homepage-module homepage-module--flex">
      <div className="content-wrapper mobile-fullbleed">
        {articles.length > 0 ? (
          <>
            <h2>
              <span>{data.name}</span>
            </h2>
            <HomepageFlexModule type="column" {...props} title={data.title} articles={articles} />
            <a href={`/browse/${data.id.replace(/_/g, '-')}`} className="btn">
              {i18n('home.index.view_all')}
            </a>
          </>
        ) : (
          <HomepageColumnModulePlaceholder {...props} />
        )}
      </div>
    </div>
  )
}

const HomepageColumnModulePlaceholder = ({ ...props }) => (
  <>
    <h2>
      <span className="bg-white h-20 w-2/4">
        <div className="animate-pulse bg-gray-5 h-full w-full" />
      </span>
    </h2>
    <HomepageFlexModulePlaceholder {...props} />
    <a href="#" className="btn">
      {i18n('home.index.view_all')}
    </a>
  </>
)
