import classNames from 'classnames'
import ImgixHelper from 'helpers/ImgixHelper'
import React from 'react'
import ReactDOM from 'react-dom'
import MediaQuery from 'react-responsive'
import RatioContainer from '../shared/RatioContainer'

const ArticleTitle = ({ article }) => {
  return (
    <h3 className="article-title leading-tight">
      <span>{article.metadata.column_name}</span>
      {article.message.title}
    </h3>
  )
}

const ArticleHeroImage = ({ article }) => {
  const ix = new ImgixHelper(article.media.image.url)
  return (
    <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
      <div className="play-button">
        <svg className="icon icon-play">
          <use xlinkHref="#icon-play" />
        </svg>
      </div>
    </div>
  )
}
export default class HomepageVideoModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hero_video_playing: false
    }
  }

  componentDidMount() {
    this.setScrollerWidth()
    window.addEventListener('resize', this.setScrollerWidth.bind(this))
  }

  setScrollerWidth() {
    let articlesList = ReactDOM.findDOMNode(this).querySelector('.articles-list')

    if (window.innerWidth < 769) {
      let articles = articlesList.querySelectorAll('.article')
      let articlesListWidth = articles[0].clientWidth * articles.length + 40
      articlesList.style.width = articlesListWidth + 'px'
    } else {
      articlesList.style.width = ''
    }
  }

  getHeroArticle() {
    return this.getHeroArticleMarkup(this.props.articles[0], 'hero')
  }

  getArticles() {
    return this.props.articles.slice(1).map(this.getArticleMarkup)
  }

  playHeroVideo(e) {
    e.preventDefault()
    this.setState({
      hero_video_playing: true
    })
    window.dataLayer.push({
      event: 'heroVideoClick'
    })
  }

  getVideoEmbedCode(article) {
    let video = article.media.video
    if (video.type === 'vimeo') {
      return `//player.vimeo.com/video/${video.id}?color=ffffff&byline=0&portrait=0&autoplay=1`
    } else if (video.type === 'youtube') {
      return `//youtube.com/embed/${video.id}?autoplay=1`
    } else if (video.type === 'brightcove') {
      return `//players.brightcove.net/6058082952001/${__H_CONFIG__.brightcovePlayerId}/index.html?videoId=${video.id}`
    }
  }

  getHeroArticleMarkup(article, key) {
    let classes = ['article', 'article--hero']

    return (
      <div className={classes.join(' ')} key={key}>
        <MediaQuery query={`(max-width: 1088px)`}>
          <a
            href={article.action.share_url}
            className="article-hero-image__link"
          >
            <ArticleHeroImage article={article} />
            <ArticleTitle article={article} />
          </a>
        </MediaQuery>
        <MediaQuery query={`(min-width: 1088px)`}>
          <a
            href="#"
            onClick={this.playHeroVideo.bind(this)}
            className="article-hero-image__link"
          >
            <ArticleHeroImage article={article} />
            {!this.state.hero_video_playing && <ArticleTitle article={article} />}
          </a>
        </MediaQuery>

        <MediaQuery query={`(min-width: 1088px)`}>
          <div>
            {this.state.hero_video_playing && (
              <iframe
                src={this.getVideoEmbedCode.call(this, article)}
                frameBorder="0"
                allowFullScreen
                className="article--hero__video-embed"
              />
            )}
          </div>
        </MediaQuery>
      </div>
    )
  }

  getArticleMarkup(article, key) {
    let classes = ['article']
    const ix = new ImgixHelper(article.media.image.url)

    return (
      <div className={classes.join(' ')} key={key}>
        <a
          href={article.action.share_url}
          className="article-hero-image__link"
        >
          <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}></div>
          <ArticleTitle article={article} />
        </a>
      </div>
    )
  }

  render() {
    return (
      <div className='mb-2'>
        {this.getHeroArticle()}
        <div className="articles-scroller">
          <div className="articles-list mt-1.5">{this.getArticles()}</div>
        </div>
        <div className="articles-scroller-mask" />
      </div>
    )
  }
}

export const HomepageVideoModulePlaceholder = () => (
  <div>
    <div className={classNames('article', 'article--hero', 'animate-pulse')}>
      <div className="article-hero-image bg-gray-5" style={{ paddingBottom: '56.25%' }} />
    </div>
    <div className="articles-scroller mt-1.5">
      <div className="articles-list">
        {[...Array(2).keys()].map(i => (
          <div key={i} className="animate-pulse article">
            <div className="mb-2 relative">
              <RatioContainer width={16} height={9}>
                <div className="absolute inset-0 bg-gray-5"></div>
              </RatioContainer>
            </div>
            <div className="h-4 w-2/3 bg-gray-5 mt-2"></div>
            <div className="h-4 w-full bg-gray-5 mt-2"></div>
          </div>
        ))}
      </div>
    </div>
  </div>
)
