/* eslint no-console:0 */

import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineArticle = lazyComponent(() => import('site/components/magazine/MagazineArticle'), <LazyFallback />)
const MagazineVolume8 = lazyComponent(() => import('site/components/magazine/articles/Volume8'), <LazyFallback />)
const MagazineVolume81 = lazyComponent(
  () => import('site/components/magazine/articles/Volume8/grid.js'),
  <LazyFallback />
)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineVolume8: function magVol8(props) {
    return <MagazineArticle component={MagazineVolume8} {...props} />
  },
  MagazineVolume81: function magVol81(props) {
    return <MagazineArticle component={MagazineVolume81} {...props} />
  }
})
