import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

export default function useWindowSize(debounceTime = 50) {
  // Initialize state with undefined width/height so server and client renders match
  const [height, setHeight] = useState(undefined)
  const [width, setWidth] = useState(undefined)

  // Handler to call on window resize
  const handleResize = debounce(() => {
    // Set window width/height to state
    setHeight(prevVal => (prevVal !== window.innerHeight ? window.innerHeight : prevVal))
    setWidth(prevVal => (prevVal !== window.innerWidth ? window.innerWidth : prevVal))
  }, debounceTime)

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return [width, height]
}
