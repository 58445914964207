/* eslint no-console:0 */

import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineArticle = lazyComponent(() => import('site/components/magazine/MagazineArticle'), <LazyFallback />)
const MagazineVolume9 = lazyComponent(() => import('site/components/magazine/articles/Volume9'), <LazyFallback />)
const MagazineVolume91 = lazyComponent(
  () => import('site/components/magazine/articles/Volume9/typeB.js'),
  <LazyFallback />
)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineVolume9: function magVol9(props) {
    return <MagazineArticle component={MagazineVolume9} {...props} />
  },
  MagazineVolume91: function magVol91(props) {
    return <MagazineArticle component={MagazineVolume91} {...props} />
  }
})
