import React from 'react'
import { nt } from 'helpers/i18n'
import LazyImage from '@hodinkee/bezel-ui/es/Components/atoms/LazyImage/LazyImage'

const i18n = nt('home.index.marketing')
const ariaI18n = nt('home.accessibility')

export default function HomepageAppDownloadModule({ ios_app_store_link, google_play_link }) {
  const ariaLabel = `${i18n('get_the_app')}, ${ariaI18n('new_window')}`

  return (
    <div className="marketing-module marketing-module--app-download">
      <div className="marketing-module__content">
        <h3>{i18n('get_the_app')}</h3>
        <p>{i18n('app_desc')}</p>
        <div className="marketing-module__app-download-badges">
          <a className="cta-badge" href={ios_app_store_link} target="_blank" aria-label={ariaLabel}>
            <LazyImage src="https://hodinkee-production.s3.amazonaws.com/site/app_store_badge.png" ratio={[118,40]} size="auto" className='' />
          </a>
          <a className="cta-badge" href={google_play_link} target="_blank" aria-label={ariaLabel}>
            <LazyImage src="https://hodinkee-production.s3.amazonaws.com/site/google-play-badge.png" ratio={[135,40]} size="auto" className='' />
          </a>
        </div>
      </div>
      <div className="marketing-module__ios-teaser-image">
        <a className="marketing-module__image-link" href={ios_app_store_link} target="_blank" aria-label={ariaLabel}>
          <LazyImage src="https://hodinkee.imgix.net/site/app-teaser-5.png?fm=png&auto=format&q=55&usm=12" ratio={[]} size="auto" className='' />
        </a>
      </div>  
    </div>
  )
}
