import React from 'react'
import SvgIcon from './SvgIcon'
import { useMediaQuery } from 'react-responsive'

export default function NavigationIcon({ id, alt, className = '' }) {
  const mobile = useMediaQuery({ query: `(max-width: 639px)` })

  return (
    <SvgIcon
      id={id}
      height={mobile ? "16" : "20"}
      width={mobile ? "16" : "20"}
      alt={alt}
      className={className}
    />
  )
}