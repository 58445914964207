import React from 'react'

export default class HomepageWatch101Module extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ref_post: {}
    }
  }

  componentWillMount() {
    fetch('/api/v1/watch101/random')
      .then(resp => resp.json())
      .then(json => this.setState({ ref_post: json.reference_post }))
  }

  render() {
    const { ref_post } = this.state
    if (!ref_post.id) return null

    return (
      <div className="homepage-module homepage-module--watch-101">
        <div className="content-wrapper">
          <div className="module-title">
            <h2 className="no-border">Watch 101</h2>
          </div>
          <div className="main-col">
            <div className="image">
              {(() => {
                if (ref_post.image) {
                  return <img src={ref_post.image.url} />
                }
              })()}
            </div>
            <p className="title">{ref_post.title}</p>
            <p className="description">{ref_post.description[0].text}</p>
            <a className="learn-more" href={`/watch101/${ref_post.slug}`}>
              Learn More
            </a>
          </div>
        </div>
      </div>
    )
  }
}
