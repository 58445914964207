import { useEffect, useState } from 'react'

const DEFAULT_OPTIONS = {
  config: { rootMargin: '-200px 0px 0px 0px' }
}

/**
 * This custom hooks abstracts the usage of the Intersection Observer with React components.
 *
 * @param {React.Ref} ref DOM element to be observed
 * @param {Object} options
 * @param {Object} options.config IntersectionObserver config properties
 */
export default function useIntersectionObserver(ref, options = DEFAULT_OPTIONS) {
  const [observer, setObserver] = useState(null)
  const [visible, setVisible] = useState(false)

  // create the observer
  useEffect(() => {
    setObserver(new IntersectionObserver(observerCallbackFunction))
  }, [options])

  // initialize the observer to watch the element
  useEffect(() => {
    if (!observer || !ref.current) return
    observer.observe(ref.current, options.config)
    return () => {
      if (observer && ref.current) observer.unobserve(ref.current)
    }
  }, [observer, ref, options])

  /**
   * This update the status when the component is visible
   */
  const observerCallbackFunction = entries => {
    let val = false
    entries.forEach(entry => {
      val = val || entry.isIntersecting
    })
    setVisible(val)
  }

  return [visible, observer]
}
