/* eslint no-console:0 */
import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const LeicaProfiles = lazyComponent(
  () => import('site/components/sponsored_content/Leica/Profiles'),
  <FullscreenFallback />
)

WebpackerReact.setup({ LeicaProfiles })
