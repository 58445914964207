import memoize from 'lodash.memoize'

/**
 * extend obj function
 */
function extend(a, b) {
  for (var key in b) {
    if (b.hasOwnProperty(key)) {
      a[key] = b[key]
    }
  }
  return a
}

// A little feature detection helper courtsey of https://github.com/filamentgroup/fixed-sticky/blob/master/fixedsticky.js#L3-L15
function featureTest(property, value, noPrefixes) {
  var prop = property + ':',
    el = document.createElement('test'),
    mStyle = el.style

  if (!noPrefixes) {
    mStyle.cssText = prop + ['-webkit-', '-moz-', '-ms-', '-o-', ''].join(value + ';' + prop) + value + ';'
  } else {
    mStyle.cssText = prop + value
  }
  return mStyle[property].indexOf(value) !== -1
}

function getHostname(url) {
  var a = document.createElement('a')
  a.href = url
  return a.hostname
}

function pluralize(num, base_string) {
  var text = base_string + (num !== 1 ? 's' : '')
  return num + ' ' + text
}

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

function isElementInView(element) {
  const docViewTop = window.scrollY
  const docViewBottom = docViewTop + window.innerHeight

  const elemTop = element?.getBoundingClientRect().top + docViewTop
  const elemBottom = elemTop + element?.offsetHeight

  return elemBottom <= docViewBottom && elemTop >= docViewTop
}

const viewportBreakpoints = {
  mobile: 481,
  tablet: 668,
  tabletWide: 741,
  desktopNarrow: 769,
  desktop: 1025,
  desktopWide: 1441
}

const arrayLookahead = (array, current, steps) => {
  const length = array.length - 1
  const nextStep = current + steps

  if (nextStep < 0) {
    return length + 1 + nextStep
  } else if (nextStep > length) {
    return nextStep - (length + 1)
  } else {
    return nextStep
  }
}

export function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num
}

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

export function rand(min = 0, max = 100) {
  return Math.floor(Math.random() * (+max - +min)) + +min
}

export function feetToMeters(value) {
  return value / 3.281
}

export const aspectRatioToPercent = memoize(function aspectRatioToPercent(ratio) {
  const splitRatio = ratio.split(':')
  return ((splitRatio[1] / splitRatio[0]) * 100).toFixed(2)
})

export function calculateGcd(a, b) {
  return b === 0 ? a : calculateGcd(b, a % b)
}

export const calculateAspectRatio = memoize(
  function calculateAspectRatio(width, height) {
    const gcd = calculateGcd(width, height)
    return `${width / gcd}:${height / gcd}`
  },
  function resolver(a, b) {
    return `${a}w_${b}h`
  }
)

export const swipeConfidenceThreshold = 10000
export const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity
}

export const getSwipeDirection = (x, velocity) => {
  const swipe = swipePower(x, velocity)
  return swipe < -swipeConfidenceThreshold ? 1 : swipe > swipeConfidenceThreshold ? -1 : 0
}

export const domReady = cb => {
  if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
    cb()
  } else {
    document.addEventListener('DOMContentLoaded', cb)
  }
}

export const FOCUSABLE_SELECTORS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

export { arrayLookahead, extend, isElementInView, featureTest, getHostname, pluralize, guid, viewportBreakpoints }

/**
 * Handles the compatibility issues with media query
 * event listener for old browsers .
 *
 * @param {MediaQueryList} media The object for the media query.
 * @param {Function} handler Callback function to run when the media query status has changed.
 * @param {Object}[args={}] Additional arguments for addEventListener if is supported.
 */
export const mediaChangeListener = (media, handler, args = {}) => {
  /* does not exists in old browsers */
  if (media.addEventListener) {
    media.addEventListener('change', handler, args)
  } else {
    /* deprecated in new browsers */
    media.addListener(handler)
  }
}

export const KEY_CODE_MAP = {
  TAB: 9,
  ENTER: 13,
  P: 80,
  N: 78,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  ESCAPE: 27
}
