import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const TimexTeaser = lazyComponent(
  () => import('site/components/sponsored_content/LimitedEditions/TimexTeaser'),
  <FullscreenFallback />
)

WebpackerReact.setup({ TimexTeaser })
