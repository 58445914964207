import React, { useEffect, useRef, useState } from 'react'
import HomepagePopularBrand, { HomepagePopularBrandPlaceholder } from './HomepagePopularBrand'
import i18n from 'helpers/i18n'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import usePrevious from '../../hooks/usePrevious'

export default function HomepagePopularBrandsModule({ brand_ids }) {
  const [data, setData] = useState([])
  const [error, setError] = useState(!brand_ids || brand_ids.length === 0)
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)
  const prevProps = usePrevious({ brand_ids })

  useEffect(() => {
    if ((visible && data.length == 0) || (visible && prevProps.brand_ids != brand_ids)) {
      loadBrands()
    }
  }, [visible])

  useEffect(() => {
    if (data.length > 0 && prevProps.brand_ids != brand_ids) {
      loadBrands()
    }
    if (mainRef.current) imgix.fluid(mainRef.current)
  }, [data])

  const loadBrands = async () => {
    if (!brand_ids || brand_ids.length === 0) return
    const params = brand_ids
      .sort((a, b) => a - b)
      .map(id => `ids[]=${id}`)
      .join('&')

    try {
      const resp = await fetch(`/api/v1/homepage/brands?${params}`)
      if (resp.status === 200) {
        const json = await resp.json()
        setData(json)
      } else {
        setError(true)
      }
    } catch (e) {
      setError(true)
    }
  }

  if (error) return null

  return (
    <div ref={mainRef} className="homepage-module homepage-module--popular-brands">
      <div className="content-wrapper">
        <p className="title">{i18n('home.index.popular_brands')}</p>
        <div className="popular-brands-container">
          {data.length === 0 ? (
            <>
              {[...Array(6).keys()].map(i => (
                <HomepagePopularBrandPlaceholder key={i} />
              ))}
            </>
          ) : (
            data.map(item => <HomepagePopularBrand key={item.brand.id} brand={item.brand} articles={item.articles} />)
          )}
        </div>
      </div>
    </div>
  )
}
