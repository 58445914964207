import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const GShockToughTour2 = lazyComponent(
  () => import('site/components/sponsored_content/GShockToughTour2'),
  <FullscreenFallback />
)

WebpackerReact.setup({ GShockToughTour2 })
