/* eslint no-console:0 */

import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineArticle = lazyComponent(() => import('site/components/magazine/MagazineArticle'), <LazyFallback />)
const MagazineVolume11 = lazyComponent(() => import('site/components/magazine/articles/Volume11'), <LazyFallback />)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineVolume11: function magVol10(props) {
    return <MagazineArticle component={MagazineVolume11} {...props} />
  }
})
