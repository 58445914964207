import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const GShockSettingTheTone = lazyComponent(
  () => import('site/components/sponsored_content/GShockSettingTheTone'),
  <FullscreenFallback />
)

WebpackerReact.setup({ GShockSettingTheTone })
