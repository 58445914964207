import React from 'react'
import i18n from 'helpers/i18n'

const ArticleBadge = ({ article, ...props }) => {
  if (article.action.subtype === 'shop_article') {
    return <div {...props}>{i18n('article.badge.in_the_shop')}</div>
  } else if (article.action.subtype === 'watches_and_wonders_article') {
    return <div {...props}>{i18n('article.badge.watches_and_wonders')}</div>
  } else if (article.metadata.sponsored_by) {
    return <div {...props}>{i18n('article.badge.in_partnership')}</div>
  } else if (article.metadata.source) {
    return <div {...props}>From {article.metadata.source}</div>
  }

  return null
}

export default ArticleBadge
