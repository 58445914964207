import React, { useEffect, useRef, useState } from 'react'
import HomepageVideoModule, { HomepageVideoModulePlaceholder } from './HomepageVideoModule'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import usePrevious from '../../hooks/usePrevious'
import { nt } from 'helpers/i18n'
const i18n = nt('home.index')

export default function HomepageFeaturedVideosModule({ article_ids }) {
  const [data, setData] = useState([])
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)
  const prevProps = usePrevious({ article_ids })

  useEffect(() => {
    if (visible && data.length == 0) fetchData()
    if (data.length != 0 && prevProps?.article_ids != article_ids) {
      fetchData()
    }
  }, [visible])

  useEffect(() => {
    if (data.length > 0 && mainRef.current) imgix.fluid(mainRef.current)
  }, [data])

  const fetchData = async () => {
    if (!article_ids || article_ids.length === 0) return
    const params = article_ids.map(id => `ids[]=${id}`).join('&')

    const resp = await fetch(`/api/v1/homepage/featured_videos?${params}`)
    if (resp.status === 200) {
      const json = await resp.json()
      setData(json.cards)
    }
  }

  if (!article_ids || article_ids.length === 0) return

  return (
    <div ref={mainRef}>
      <div className="my-5">
        <div className="text-center">
          <div className="ad-title">ADVERTISEMENT</div>
          <div className='htl-ad htlad-HOD_Home_WideBillboard_Pos3'></div>
        </div>
      </div>

      <div className="homepage-module homepage-module--top-videos">
        <div className="content-wrapper">
          <a href="/videos" className="view-all">
            {i18n('view_all_videos')}
          </a>
          <p className="title">{i18n('featured_videos')}</p>
          {data.length === 0 ? <HomepageVideoModulePlaceholder /> : <HomepageVideoModule articles={data} />}

          <a href="/videos" className="btn mx-auto md:mx-0 lg:mx-auto">
            {i18n('view_all_videos')}
          </a>
        </div>
      </div>
    </div>
  )
}
