import React from 'react'
import ReactDOM from 'react-dom'
import ImgixHelper from 'helpers/ImgixHelper'
import { ByAuthorOrCollaborationWith } from '../ArticleBlock'
import _ from 'lodash'
import { PlaceholderCardList } from '../search/PlaceholderCard'

/**
 * Handles Collections, Columns, and Packages
 */
export default class HomepageFlexModule extends React.Component {
  componentDidMount() {
    imgix.fluid(ReactDOM.findDOMNode(this))
  }

  componentDidUpdate() {
    ReactDOM.findDOMNode(this)
      .querySelectorAll('.imgix-fluid')
      .forEach(fluidElement => {
        fluidElement.url = new imgix.URL(fluidElement.getAttribute('data-src'))
        imgix.fluid(ReactDOM.findDOMNode(this), { updateOnResizeDown: true }).updateSrc(fluidElement)
      })
  }

  getHeroArticle() {
    const article = this.props.articles[0]
    const ix = new ImgixHelper(article.media.image.url)

    return (
      <div className={`article article--hero align-${this.props.alignment}`}>
        <a
          href={article.action.share_url}
          className="article-link"
        >
          <div className="article-hero-container">
            <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()} />
          </div>
          <div className="article-info-container">
            <h3 className="article-title leading-tight">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </h3>
            <h4 className="article-lede" dangerouslySetInnerHTML={{ __html: article.message.text_html }} />
            <p className="article-meta">
              <ByAuthorOrCollaborationWith authors={article.authors} tags={article.metadata.tags} />
            </p>
          </div>
        </a>
      </div>
    )
  }

  getArticles() {
    const title = this.props.title || ''

    return this.props.articles.slice(1).map(function (article, i) {
      const ix = new ImgixHelper(article.media.image.url)

      return (
        <div className="article" key={i}>
          <a
            href={article.action.share_url}
          >
            <div className="article-hero-container">
              <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()} />
            </div>
            <h3 className="article-title leading-tight">
              <span>{article.metadata.column_name}</span>
              {article.message.title}
            </h3>
          </a>
          <p className="article-meta hide-until-mobile">
            <ByAuthorOrCollaborationWith authors={article.authors} tags={article.metadata.tags} />
          </p>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        {this.getHeroArticle()}
        <div className="articles-grid">{this.getArticles()}</div>
      </div>
    )
  }
}

export const HomepageFlexModulePlaceholder = ({ alignment }) => (
  <div>
    <div className={`animate-pulse article article--hero align-${alignment}`}>
      <div className="article-hero-container">
        <div className="article-hero-image bg-gray-5" />
      </div>
      <div className="article-info-container">
        <h3 className="article-title leading-tight w-full bg-gray-5">&nbsp;</h3>
        <h4 className="article-lede 2/3 bg-gray-5">&nbsp;</h4>
        <p className="article-meta w-1/4 bg-gray-5 ">&nbsp;</p>
      </div>
    </div>
    <div className="articles-grid">
      <PlaceholderCardList className="article" size={3} ratio={[16, 9]} />
    </div>
  </div>
)
