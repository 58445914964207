import ImgixHelper from 'helpers/ImgixHelper'
import React, { useEffect, useRef, useState } from 'react'
import i18n from 'helpers/i18n'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

export default function HomepageLastWeekTopStories() {
  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)

  useEffect(() => {
    if (visible && data.length === 0) {
      fetchData()
    }
  }, [visible])

  useEffect(() => {
    if (data.length > 0) {
      imgix.fluid(mainRef.current)
    }
  }, [data])

  const fetchData = async () => {
    const resp = await fetch('/api/v1/homepage/last_week_top_stories')
    if (resp.status === 200) {
      const json = await resp.json()
      setData(json.cards)
    } else {
      setError(true)
    }
  }

  if (error) return null

  return (
    <div ref={mainRef} className="homepage-module homepage-module--top-discussions">
      <div className="content-wrapper">
        <h2>
          <span>{i18n('home.index.last_week_top_stories')}</span>
        </h2>
        <div className='htl-ad htlad-HOD_Home_RR_Pos2'></div>
        <ol className="articles-list">
          {data.length === 0 ? (
            <ArticlesPlaceholder />
          ) : (
            data.map(function (article, i) {
              const ix = new ImgixHelper(article.media.image.url)
              return (
                <li key={i}>
                  <a href={article.action.share_url} className="article-link">
                    <div className="article-hero-image">
                      <div className="imgix-fluid imgix-fluid-bg" data-src={ix.getURL()} />
                    </div>

                    <p className="article-title">
                      <span>{article.metadata.column_name}</span>
                      {article.message.title}
                    </p>
                  </a>
                </li>
              )
            })
          )}
        </ol>
      </div>
    </div>
  )
}

export const ArticlesPlaceholder = () => {
  return [...Array(5).keys()].map(i => (
    <li key={i}>
      <a href="#" className="article-link animate-pulse">
        <div className="article-hero-image">
          <div className="imgix-fluid imgix-fluid-bg bg-gray-5" />
        </div>
        <div className="article-title">
          <div className="h-5 w-full bg-gray-5 mb-2" />
          <div className="h-5 w-2/3 bg-gray-5" />
        </div>
      </a>
    </li>
  ))
}
