import classNames from 'classnames'
import React from 'react'
import RatioContainer from '../shared/RatioContainer'

export const PlaceholderCard = ({ className, ratio }) => (
  <div className={classNames('animate-pulse', className)}>
    <div className="mb-2 relative">
      <RatioContainer width={ratio[0]} height={ratio[1]}>
        <div className="absolute inset-0 bg-gray-5"></div>
      </RatioContainer>
    </div>
    <div className="h-4 w-2/3 bg-gray-5 mt-2"></div>
    <div className="h-4 w-full bg-gray-5 mt-2"></div>
    <div className="h-4 w-1/4 bg-gray-5 mt-2 "></div>
  </div>
)

export const PlaceholderCardList = ({ className, size = 12, ratio }) =>
  [...Array(size).keys()].map((i) => <PlaceholderCard className={className} key={i} ratio={ratio} />)
