/* eslint no-console:0 */

import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineArticle = lazyComponent(() => import('site/components/magazine/MagazineArticle'), <LazyFallback />)
const MagazineVolumePaperturn = lazyComponent(
  () => import('site/components/magazine/articles/PaperTurn'),
  <LazyFallback />
)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineVolumePaperturn: props => <MagazineArticle component={MagazineVolumePaperturn} {...props} />
})
