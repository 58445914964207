import { domReady, FOCUSABLE_SELECTORS } from 'site/helpers'

domReady(() => {
  document.querySelectorAll('.toggle-nav-dropdown').forEach(btn => {
    const submenu = btn.querySelector('.menu-items')

    // State handling for the current open state of the submenu
    let menuOpen = false
    const setMenuOpen = (open, autoFocus = null) => {
      menuOpen = open
      btn.setAttribute('aria-expanded', open)

      if (open) {
        btn.classList.add('menu-open')
        if (autoFocus) submenu.querySelector(FOCUSABLE_SELECTORS).focus()
      } else {
        btn.classList.remove('menu-open')
      }
    }

    // Event listener that will close the submenu if focus leaves it.
    // This will automatically focus the next sibling in the navbar to ensure
    // proper tab order. It tracks the last focused element in order to know which
    // direction you're tabbing.
    let lastFocusedElement
    document.addEventListener(
      'focus',
      e => {
        if (!menuOpen) return
        if (!submenu.contains(e.target) && !btn.contains(e.target)) {
          const focusableItems = submenu.querySelectorAll(FOCUSABLE_SELECTORS)

          const nextFocus =
            lastFocusedElement === focusableItems[0]
              ? btn.parentElement.previousElementSibling // btn.parentElement = menu
              : btn.parentElement.nextElementSibling // btn.parentElement = menu

          setMenuOpen(false)

          nextFocus?.querySelector(FOCUSABLE_SELECTORS)?.focus()
        }

        lastFocusedElement = e.target
      },
      true
    )

    // Watches for clicks outside of the menu to close it
    document.addEventListener('click', e => {
      if (menuOpen && !btn.contains(e.target) && !submenu.contains(e.target)) {
        setMenuOpen(false)
      }
    })

    // Click handler that toggles the menu, also works for the Enter key
    btn.addEventListener('click', e => {
      if (e.target.tagName.toLowerCase() !== 'a') {
        setMenuOpen(!menuOpen, e.detail === 0)
      }
    })
  })
})
