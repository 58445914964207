import React from 'react'
import { nt } from 'helpers/i18n'
const ariaI18n = nt('home.accessibility')

const HomepageInstagramPost = ({ post }) => {
  const ariaLabel = `Instagram post, ${ariaI18n('new_window')}`

  return (
    <div className="post">
      <div className="post__inner">
        <a href={post.link} target="_blank" aria-label={ariaLabel}>
          <img className="image" src={post.images.standard_resolution.url} />
          <div className="meta-wrapper">
            <div className="meta">
              <p>
                <svg className="icon">
                  <use xlinkHref="#icon-heart" />
                </svg>
                {post.likes}

                <svg className="icon icon-speech-bubble">
                  <use xlinkHref="#icon-speech-bubble" />
                </svg>
                {post.comments}
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default HomepageInstagramPost
