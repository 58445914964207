/* eslint no-console:0 */

import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineArticle = lazyComponent(() => import('site/components/magazine/MagazineArticle'), <LazyFallback />)
const MagazineVolume10 = lazyComponent(() => import('site/components/magazine/articles/Volume10'), <LazyFallback />)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineVolume10: function magVol10(props) {
    return <MagazineArticle component={MagazineVolume10} {...props} />
  }
})
