/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const OrientStar1962 = lazyComponent(
  () => import('site/components/sponsored_content/OrientStar1962'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  OrientStar1962: function a(props) {
    return <SponConWrapper template={OrientStar1962} {...props} />
  }
})
