import React, { Component } from 'react'

export default class ErrorBoundary extends Component {
  state = {
    hasError: false
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorState || null
    }

    return this.props.children
  }
}
