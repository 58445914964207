import React from 'react'
import RatioContainer from './RatioContainer'
import Imgix from 'react-imgix'

const lazySizesAttrConfig = {
  src: 'data-src',
  srcSet: 'data-srcset',
  sizes: 'data-sizes'
}

const LazyLoadImage = ({ ratio, src, alt, className, imgixParams = { fit: 'max', q: 88 }, ...rest }) => (
  <RatioContainer className={className} width={ratio[0]} height={ratio[1]}>
    <Imgix
      htmlAttributes={{ alt }}
      className="lazyload"
      imgixParams={imgixParams}
      attributeConfig={lazySizesAttrConfig}
      src={src}
      {...rest}
    />
  </RatioContainer>
)

export default LazyLoadImage

export const LazyLoadProductCardImage = ({ src, alt, imgixParams = { fit: 'max', q: 88 }, ...rest }) => (
  <Imgix
    htmlAttributes={{ alt, loading: 'lazy' }}
    className="lazyload"
    imgixParams={imgixParams}
    attributeConfig={lazySizesAttrConfig}
    src={src}
    {...rest}
  />
)

export const LazyLoadCollectionCardImage = ({ src, alt, imgixParams = { fit: 'max', q: 88 }, ...rest }) => (
  <Imgix
    htmlAttributes={{ alt, loading: 'lazy' }}
    className="lazyload"
    imgixParams={imgixParams}
    attributeConfig={lazySizesAttrConfig}
    src={src}
    {...rest}
  />
)