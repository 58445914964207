import React, { useEffect, useRef } from 'react'
import get from 'lodash/get'
import ImgixHelper from 'helpers/ImgixHelper'
import Authors from '../Authors'
import ArticleBadge from '../ArticleBadge'

export default function HomepageHeroArticle({ article }) {
  const ix = new ImgixHelper(get(article, 'media.image.url'))
  const linkTarget = article.action.subtype === 'shop_article' ? '_blank' : '_self'

  const moduleEl = useRef(null)
  useEffect(() => {
    imgix.fluid(moduleEl.current)
  })

  return (
    <div className="article" ref={moduleEl}>
      <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
        <ArticleBadge article={article} className="article__badge" />
        <a href={article.action.share_url} className="article-hero-image__link" target={linkTarget} />
      </div>
      <div className="article-info">
        <h3 className="article-title">
          <a href={article.action.share_url} className="article-link" target={linkTarget}>
            <span>{article.metadata.column_name}</span>
            {article.message.title}
          </a>
        </h3>
        <p className="article-meta mt-2">
          <Authors authors={article.authors} />
        </p>
      </div>
    </div>
  )
}
