/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const TudorBlackBayBuyersGuide2022 = lazyComponent(
  () => import('site/components/sponsored_content/TudorBlackBayBuyersGuide2022'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  TudorBlackBayBuyersGuide2022: function a(props) {
    return <SponConWrapper template={TudorBlackBayBuyersGuide2022} {...props} />
  }
})
