import React from 'react'
import SunCalc from 'suncalc'

import mask from './moonphase/mask.svg'
import moonSmall from './moonphase/moonphase-small.svg'
import moon from './moonphase/moonphase.svg'

import { nt } from 'helpers/i18n'
const i18n = nt('moonphase')

export default function Moonphase({ moonphaseSize, phase }) {
  const rotation = phase * 180.0 + 90.0

  return (
    <div className="moonphase">
      <div className="mask">
        <img src={mask} />
      </div>
      <div className="moon" style={{ transform: `rotate(${rotation}deg)` }}>
        <img src={moonphaseSize === 'large' ? moon : moonSmall} />
      </div>
    </div>
  )
}
