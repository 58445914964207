import React from 'react'
import ImgixHelper, { withImgix } from 'helpers/ImgixHelper'

import RatioContainer from '../../shared/RatioContainer'
import _ from 'lodash'

export default function HomepageShopCollection({ feature }) {
  const ix = new ImgixHelper(feature.image)

  return (
    <div className="feature">
      <RatioContainer className="bg-gray-5" width={4} height={5}>
        <a
          href={feature.url}
          className="feature-hero-image__link"
        >
          <div className="feature-hero-image">
            <img
              className="lazyload bg-gray-5"
              loading="lazy"
              data-src={withImgix(ix.getURL(), { w: 297, h: 371 })}
              alt={_.startCase(feature.title) != '' ? _.startCase(feature.title) : ' '}
            />

            <div className="feature-info">
              <div className="feature-title">
                <span>{(feature.title).replaceAll('_', ' ')}</span>
              </div>

              <div className="feature-headline">
                <span>{feature.headline}</span>
              </div>
            </div>
          </div>
        </a>
      </RatioContainer>
    </div>
  )
}
