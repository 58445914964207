/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const CasioGshock = lazyComponent(() => import('site/components/sponsored_content/CasioGshock'), <FullscreenFallback />)

WebpackerReact.setup({
  CasioGshock: props => <SponConWrapper template={CasioGshock} {...props} />
})
