/* eslint no-console:0 */
import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const CitizenPromasterLand = lazyComponent(
  () => import('site/components/sponsored_content/CitizenPromaster/Land'),
  <FullscreenFallback />
)
const CitizenPromasterSea = lazyComponent(
  () => import('site/components/sponsored_content/CitizenPromaster/Sea'),
  <FullscreenFallback />
)
const CitizenPromasterAir = lazyComponent(
  () => import('site/components/sponsored_content/CitizenPromaster/Air'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  CitizenPromasterAir,
  CitizenPromasterLand,
  CitizenPromasterSea
})
