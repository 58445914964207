import I18n from "i18n-js";

if (window.I18n) {
  const { locale, defaultLocale } = window.I18n;
  window.I18n = I18n;
  window.I18n.locale = locale;
  window.I18n.defaultLocale = defaultLocale;
} else {
  window.I18n = I18n;
}

const loadLocale = name => import(`i18n/${name}.${I18n.locale}.js`);
export default loadLocale;
