import React from 'react'

import HomepageShopCollection from './HomepageShopCollection'
import ErrorBoundary from '../../ErrorBoundary'

export default function HomepageShopCollectionItem({ item }) {
  return (
    <ErrorBoundary>
      <HomepageShopCollection feature={item} />
    </ErrorBoundary>
  )
}
