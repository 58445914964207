import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import { nt } from 'helpers/i18n'
import capitalize from 'lodash/capitalize'

import { withImgixHostname, withImgixDefaults } from 'helpers/ImgixHelper'
import LazyLoadImage from './shared/LazyLoadImage'
import Authors from './homepage_modules/Authors'

const i18n = nt('article.badge')
const i18nArticle = nt('home.index')

export default function ArticleBlock({
  articleType,
  className,
  url,
  sponsoredBy,
  date,
  column,
  title,
  source,
  image,
  imageSizes
}) {
  const dateString = moment(date).format('MMM. DD YYYY')
  return (
    <div className={classnames('ArticleBlock product-card article-card', className)}>
      <a href={url} tabIndex="0" className="product-card-content">
        <div className="articles-grid-item-img-wrapper block">
          <LazyLoadImage
            ratio={[16, 9]}
            src={withImgixHostname(image)}
            imgixParams={withImgixDefaults({ ar: '16:9' })}
            sizes={imageSizes}
          />
          <ArticleBadge articleType={articleType} sponsoredBy={sponsoredBy} source={source} />
        </div>
        {date && <time className="article-time">{dateString}</time>}
        <h2 className="title article-title">
          <span>{column}</span> {title}
        </h2>
      </a>
    </div>
  )
}

const ArticleBadge = ({ articleType, sponsoredBy, source }) => {
  const badgeClass = 'absolute font-brown font-bold text-xs uppercase bottom-2.5 bg-gray-90 text-white px-2 leading-7'
  if (articleType === 'shop_article') {
    return <div className={badgeClass}>{i18n('in_the_shop')}</div>
  } else if (articleType === 'watches_and_wonders_article') {
    return <div className={badgeClass}>{i18n('watches_and_wonders')}</div>
  } else if (sponsoredBy) {
    return <div className={badgeClass}>{i18n('in_partnership')}</div>
  } else if (source) {
    return <div className={badgeClass}>From {source}</div>
  }

  return null
}

export function InCollaborationWith({ collaborationsTags }) {
  const getCollaborations = () => {
    const collaboratorsArray = collaborationsTags.map((collaborationTag, index) => {
      let collaboratorName = collaborationTag.split('|')[1].replace('_', ' ')
      return <span key={index}>{capitalize(collaboratorName)}</span>
    })

    if (!collaboratorsArray || !collaboratorsArray.length) return null

    return collaboratorsArray.reduce((accu, curr) => [accu, ', ', curr])
  }

  const collaborations = getCollaborations()
  if (!collaborations) return null

  return (
    <span>
      <span className="in-collaboration-with">{i18nArticle('collaboration_with')}</span> {collaborations}
    </span>
  )
}

export function ByAuthorOrCollaborationWith({ authors, tags = [] }) {
  const listCollaborations = tags.filter(tag => tag.includes('collab|'))

  return (
    <React.Fragment>
      {listCollaborations.length ? (
        <InCollaborationWith collaborationsTags={listCollaborations} />
      ) : (
        <Authors authors={authors} />
      )}
    </React.Fragment>
  )
}
