/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const GrandSeiko62 = lazyComponent(
  () => import('site/components/sponsored_content/GrandSeiko62'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  GrandSeiko62: function a(props) {
    return <SponConWrapper template={GrandSeiko62} {...props} />
  }
})
