import React from 'react'
import classnames from 'classnames'

const aspectRatioToPercent = ratio => {
  const splitRatio = ratio.split(':')
  return ((splitRatio[1] / splitRatio[0]) * 100).toFixed(2).replace(/\.?0+$/, '')
}

const calculateGcd = (a, b) => {
  return b === 0 ? a : calculateGcd(b, a % b)
}

const RatioContainer = props => {
  const gcd = calculateGcd(props.width, props.height)
  const ratio = `${props.width / gcd}:${props.height / gcd}`
  const style = { paddingBottom: `${aspectRatioToPercent(ratio)}%` }
  //const style = {}

  return (
    <div className={classnames('RatioContainer', props.className)} style={style}>
      {props.children}
    </div>
  )
}

export default RatioContainer
