/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const GrandSeiko60 = lazyComponent(
  () => import('site/components/sponsored_content/GrandSeiko60'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  GrandSeiko60: props => <SponConWrapper template={GrandSeiko60} {...props} />
})
