import { FOCUSABLE_SELECTORS } from 'site/helpers'

/**
 * Initialize the dropdown behavior
 *
 * @param {DOMElement} btn the current target
 */
var Dropdown = (btn, navigation, touchScreen) => {
  // Dom elements ref vars
  let menuitem, submenu, tabCaret
  // the dropdown state
  let menuOpen, lastFocusedElement

  const handleNextKeypress = e => {
    if (e.key === 'Tab') {
      if (e.shiftKey) {
        setMenuOpen(false)
      } else {
        e.preventDefault()
        submenu.querySelector(FOCUSABLE_SELECTORS).focus()
        navigation.removeEventListener('keydown', handleNextKeypress)
      }
    }
  }

  /**
   * Handles the state of the dropdow menu
   *
   * @param {bool} open The current state of the view
   */
  const setMenuOpen = open => {
    menuOpen = open
    btn.setAttribute('aria-expanded', open)
    tabCaret.setAttribute('aria-expanded', open)
    submenu.setAttribute('aria-hidden', !open)

    if (open) {
      menuitem.classList.add('is-active')
      submenu.classList.add('submenu-open')
      submenu.classList.add('nav-active')
      submenu.parentElement.classList.remove('invisible')
      navigation.addEventListener('keydown', handleNextKeypress)
      tabCaret.classList.add('rotated')
      // Watches for clicks outside of the menu to close it
      document.addEventListener('click', checkOutside)
    } else {
      menuitem.classList.remove('is-active')
      submenu.classList.remove('submenu-open')
      submenu.classList.remove('nav-active')
      submenu.parentElement.classList.add('invisible')
      navigation.removeEventListener('keydown', handleNextKeypress)
      tabCaret.classList.remove('rotated')
      // Watches for clicks outside of the menu to close it
      document.removeEventListener('click', checkOutside)
    }
  }

  /**
   * Checks if the event is related to a element outside of the dropdown.
   *
   * @param {Event} e the current event
   */
  const checkOutside = e => {
    if (menuOpen && !btn.contains(e.target) && !submenu.contains(e.target)) {
      setMenuOpen(false)
    }
  }

  /**
   * Sets the focus class to the nav tab.
   *
   * @param {Event} event the current event
   */
  const setTabFocus = event => {
    event.target.parentElement.classList.add('in-focus')
    event.target.parentElement.classList.add('nav-active')
  }

  /**
   * Removes the focus class to the nav tab.
   *
   * @param {Event} event the current event
   */
  const removeTabFocus = event => {
    event.target.parentElement.classList.remove('in-focus')
    event.target.parentElement.classList.add('nav-active')
  }

  /**
   * Removes the focus class to the nav caret.
   *
   * @param {Event} event the current event
   */
  const hideCaret = event => {
    event.target.parentElement.classList.remove('in-focus')
    event.target.classList.remove('in-focus')
  }

  /**
   * Sets the focus class to the nav caret.
   *
   * @param {Event} event the current event
   */
  const revealCaret = event => {
    event.target.parentElement.classList.add('in-focus')
    event.target.classList.add('in-focus')
  }

  /**
   * Handle the focus event for the button.
   *
   * @param {Event} e the current event
   */
  const onFocus = e => {
    if (!menuOpen) return
    if (!submenu.contains(e.target) && !btn.contains(e.target)) {
      e.preventDefault()
      const focusableItems = submenu.querySelectorAll(FOCUSABLE_SELECTORS)
      const nextFocus =
        lastFocusedElement === focusableItems[0] ? btn.parentElement : btn.parentElement.nextElementSibling

      setMenuOpen(false)
      nextFocus?.querySelector(FOCUSABLE_SELECTORS)?.focus()
    }

    lastFocusedElement = e.target
  }

  /**
   * Handle the click/touch event for the button.
   *
   * @param {Event} evt the current event
   */
  const onClick = evt => {
    const isExpanded = btn.getAttribute('aria-expanded') == 'true'
    if (touchScreen.matches && !isExpanded) {
      //the first tap should open the nav dropdown
      evt.preventDefault()
    }
    setMenuOpen(!menuOpen)
  }

  /**
   * Bind the events for the dropdown
   */
  const bind = () => {
    // Event listener that will close the submenu if focus leaves it.
    // This will automatically focus the next sibling in the navbar to ensure
    // proper tab order. It tracks the last focused element in order to know which
    // direction you're tabbing.
    document.addEventListener('focus', e => onFocus(e), true)

    // Click handler that toggles the menu, also works for the Enter key
    btn.addEventListener('click', evt => onClick(evt))

    // Hover handler that toggles the menu
    menuitem.addEventListener('mouseover', () => {
      if (!touchScreen.matches && !menuOpen) setMenuOpen(true)
    })

    // Watches for mouse moving outside of the menu to close it
    menuitem.addEventListener('mouseout', () => {
      if (!touchScreen.matches && menuOpen) setMenuOpen(false)
    })

    // prevent focus on click
    btn.addEventListener('mousedown', e => e.preventDefault())
    // Focus handlers
    btn.addEventListener('focusin', setTabFocus)
    btn.addEventListener('focusout', removeTabFocus)
    tabCaret.addEventListener('focusin', revealCaret)
    tabCaret.addEventListener('focusout', hideCaret)
  }

  /**
   * Initialize the dropdown
   */
  const init = () => {
    submenu = document.querySelector(btn.dataset.submenu)
    if (submenu === null) return

    tabCaret = btn.querySelector('.nav-tab-caret')
    const btnContainer = btn.parentElement
    menuitem = btnContainer.parentElement
    menuOpen = false
    bind()
  }

  return { init }
}

const NavDropdown = (() => {
  /**
   * Initialize the dropdown
   */
  const init = () => {
    const navigation = document.querySelector('#navigation')
    // Everything here is scoped to the navigation element. If it's not present,
    // we can bail.
    if (!navigation) return

    const touchScreen = window.matchMedia('(pointer: coarse)')
    // for each nav link initialize the dropdown
    navigation.querySelectorAll('.nav-link').forEach(btn => {
      var nd = new Dropdown(btn, navigation, touchScreen)
      nd.init()
    })
  }
  return { init }
})()

export default NavDropdown
