import React, { useEffect, useRef } from 'react'
import ImgixHelper from 'helpers/ImgixHelper'
import Authors from '../Authors'
import ArticleBadge from '../ArticleBadge'
import get from 'lodash/get'

export default function HomepageHeroHeroArticle({ article }) {
  const ix = new ImgixHelper(get(article, 'media.image.url'), {
    blend: '#f2f2f2',
    bm: 'multiply'
  })

  let classes = ['article', 'article--hero']
  if (article.action.subtype === 'shop_article' || article.metadata.sponsored_by)
    classes.push('article--hero__shop-article')

  const linkTarget = article.action.subtype === 'shop_article' ? '_blank' : '_self'

  const moduleEl = useRef(null)
  useEffect(() => {
    imgix.fluid(moduleEl.current)
  })

  return (
    <div className={classes.join(' ')} ref={moduleEl}>
      <div className="article-hero-image imgix-fluid imgix-fluid-bg" data-src={ix.getURL()}>
        <a href={article.action.share_url} className="article-hero-image__link" target={linkTarget} />
      </div>
      <div className="article-info">
        <ArticleBadge article={article} className="article--hero__badge" />

        <h3 className="article-title">
          <a href={article.action.share_url} className="article-link" target={linkTarget}>
            <span>{article.metadata.column_name}</span>
            {article.message.title}
          </a>
        </h3>
        <h4 className="article-lede">{article.message.text}</h4>
        <p className="article-meta">
          <Authors authors={article.authors} />
        </p>
      </div>
    </div>
  )
}
