import React from 'react'
import { nt } from 'helpers/i18n'
import LazyImage from '@hodinkee/bezel-ui/es/Components/atoms/LazyImage/LazyImage'

const i18n = nt('home.index.marketing')
const ariaI18n = nt('home.accessibility')

export default function HomepageInstagramFollowModule({ handle }) {
  const ariaLabel = `${i18n('follow_us_on')} Instagram, ${ariaI18n('new_window')}`

  return (
    <div className="marketing-module marketing-module--instagram">
      <div className="marketing-module__content">
        <h3>
          Follow us on Instagram
        </h3>
        <p>{i18n('instagram_desc')}</p>
        <a className="btn btn-primary" href={`http://www.instagram.com/${handle}`} target="_blank" aria-label={ariaLabel}>
          {i18n('follow')}
        </a>
      </div>
      <div className="marketing-module__ig-teaser-image">
        <a className="marketing-module__image-link" href={`http://www.instagram.com/${handle}`} target="_blank" aria-label={ariaLabel}>
          <LazyImage src="https://hodinkee.imgix.net/site/ig-teaser-4.png?fm=png&auto=format&q=55&usm=12" ratio={[]} size="auto" className='' />
        </a>
      </div>
    </div>
  )
}
