/* eslint no-console:0 */
/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const PiagetLookbook = lazyComponent(
  () => import('site/components/sponsored_content/PiagetLookbook'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  PiagetLookbook: props => <SponConWrapper template={PiagetLookbook} {...props} />
})
