import $ from 'jquery'
import React from 'react'

import {
  HomepageHeroModule,
  HomepageShopCollectionModule,
  HomepageH10Module,
  HomepageNewsletterModule,
  HomepageShopModule,
  HomepageCustomBreakerModule,
  HomepageLatestModule,
  HomepageArticleModule,
  HomepageCollectionModule,
  HomepageColumnModule,
  HomepagePackageModule,
  HomepageTopStoriesModule,
  HomepageFeaturedVideosModule,
  HomepageTopVideosModule,
  HomepageTopDiscussionsModule,
  HomepageEditorsPicksModule,
  HomepageOurEditorsModule,
  HomepagePopularBrandsModule,
  HomepageMarketingModules,
  HomepageLastWeekTopStoriesModule
} from './homepage_modules'

import ErrorBoundary from './ErrorBoundary'

export default class Homepage extends React.Component {
  static get modules() {
    return {
      hero: HomepageHeroModule,
      shop_collection: HomepageShopCollectionModule,
      'breaker.newsletter': HomepageNewsletterModule,
      'breaker.h10': HomepageH10Module,
      'breaker.article': HomepageArticleModule,
      'breaker.shop': HomepageShopModule,
      'breaker.custom': HomepageCustomBreakerModule,
      latest: HomepageLatestModule,
      'flex.collection': HomepageCollectionModule,
      'flex.column': HomepageColumnModule,
      'flex.package': HomepagePackageModule,
      'flex.top_stories': HomepageTopStoriesModule,
      'video.featured_videos': HomepageFeaturedVideosModule,
      'video.top_videos': HomepageTopVideosModule,
      'list.top_discussions': HomepageTopDiscussionsModule,
      'list.last_week_top_stories': HomepageLastWeekTopStoriesModule,
      editors_picks: HomepageEditorsPicksModule,
      our_editors: HomepageOurEditorsModule,
      popular_brands: HomepagePopularBrandsModule,
      marketing: HomepageMarketingModules
    }
  }

  static module(data) {
    let type = data.type
    if (data.subtype) type += `.${data.subtype}`

    return Homepage.modules[type]
  }

  constructor(props) {
    super(props)

    this.state = {
      config: this.props.config,
      headerBillboardHeight: 0
    }
    this.homepageRef = React.createRef()
  }

  previewHandler(e) {
    // store the value of the subscribe form plain html before this is
    // erased by the component redraw.
    const $klaviyo = $('[class^="form--newsletter klaviyo"]').html()
    this.setState({ config: e.detail, klaviyo: $klaviyo })
  }

  updateHeaderBillboardHeight = () => {
    this.setState({ headerBillboardHeight: document.getElementById('div-gpt-ad-7702661-5')?.clientHeight })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeaderBillboardHeight)

    if (this.props.preview) {
      window.removeEventListener('homepage:configUpdate', this.previewHandler.bind(this), false)
    }
  }

  componentDidMount() {
    $(this.homepageRef.current).on('click', '.article-hero-image__link, .article-link', e => {
      let module = $(e.target).closest('.homepage-module')[0]
      let moduleName = module.className.split('--')[1]
      if (moduleName === 'flex') {
        let heading = $(module).find('h2').text().replace(/\s/g, '-').toLowerCase()
        moduleName += `--${heading}`
      }
      window.dataLayer.push({
        event: 'heroModuleClick',
        moduleName: moduleName
      })
    })

    this.trackTopSixClick()

    window.addEventListener('resize', this.updateHeaderBillboardHeight)

    if (this.props.preview) {
      window.addEventListener('homepage:configUpdate', this.previewHandler.bind(this), false)
    }
  }

  /**
   * When the page is in preview mode and the componet is redraw, inject the plain
   * html of klaviyo subscribe form.
   */
  componentDidUpdate() {
    if (this.props.preview && this.state.klaviyo) {
      $('[class^="form--newsletter klaviyo"]').html(this.state.klaviyo)
    }
  }

  trackTopSixClick = () => {
    document
      .querySelector('.homepage-module--hero')
      .querySelectorAll('.package-link, .article-link')
      .forEach((ele, i) => {
        ele.dataset.index = i + 1
        const { index, gtmtitle, gtmurl } = ele?.dataset
        ele.addEventListener(
          'click',
          () => {
            window.dataLayer.push({
              event: 'top_six_click',
              click_position: index,
              click_text: gtmtitle,
              destination_url: gtmurl
            })
          },
          { once: true }
        )
      })
  }

  onWindowResize = (prevWindowSize, windowSize) => {
    if (windowSize === 'small' && prevWindowSize === 'large') {
      $('.app-wrapper').css('paddingTop', 0)
    }
    $('.app-wrapper').css('paddingTop', this.state.headerBillboardHeight)
  }

  onSlotRenderEnded = event => {
    if (!event.isEmpty && event.slot.getSlotElementId().match('div-gpt-ad-7702661-5')) {
      this.setState({ headerBillboardHeight: document?.getElementById(event.slot.getSlotElementId()).clientHeight })
      $('.app-wrapper').css('paddingTop', this.state.headerBillboardHeight)
      $(document).trigger('HomepageHeaderAdLoaded')
    }
  }

  getModules() {
    return this.state.config.map((module, i) => {
      if (module.subtype && module.subtype === 'instagram') return

      let ModuleType = Homepage.module(module)
      if (!ModuleType) return // Prevents homepage from breaking if we add a new module
      return (
        <ErrorBoundary key={`${module.type}-${i}`}>
          <ModuleType
            {...module.data}
            type={module.type}
            ad_targeting={this.props.ad_targeting}
          />
        </ErrorBoundary>
      )
    })
  }

  render() {
    return (
      <div>
        <div className="homepage-modules">{this.getModules()}</div>
      </div>
    )
  }
}
