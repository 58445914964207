import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const GShockGWG2000b = lazyComponent(
  () => import('site/components/sponsored_content/GShockGWG2000b'),
  <FullscreenFallback />
)

WebpackerReact.setup({ GShockGWG2000b })
