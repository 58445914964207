import { domReady, mediaChangeListener } from 'site/helpers'

domReady(() => {
  const navigation = document.querySelector('#navigation')

  // Everything here is scoped to the navigation element. If it's not present,
  // we can bail.
  if (!navigation) return

  /*
   * Search implementation
   */

  // Search drawer full page background
  // Transparent on desktop, but handles closing of the search drawer when clicked
  const fullPageBg = document.createElement('div')
  fullPageBg.classList.add(...'hidden fixed z-20 inset-0 bg-shade-20 lg:bg-transparent'.split(' '))
  document.body.append(fullPageBg)

  // Desktop search
  ;(function () {
    const searchInputContainer = navigation.querySelector('.search-input-container')
    const searchInput = document.querySelector(".mobile-search-container .lume-search")
    const searchNavLink = navigation.querySelector('.desktop-search-toggle')
    const cancelSearch = navigation.querySelector('.cancel-search')
    const stickyNavContainer = navigation.querySelector('#sticky-nav')
    const stickyCancelSearch = navigation.querySelector('.sticky-cancel-search')
    const stickySearchNavLink = navigation.querySelector('.sticky-search-toggle')
    const hideOnSearchContainer = navigation.querySelector('.hide-on-search')
    const stickyHideOnSearchContainer = navigation.querySelector('.sticky-hide-on-search')

    const searchFocusLost = e => {
      if (
        !e.target.classList.contains('desktop-search-toggle') &&
        !e.target.classList.contains('sticky-search-toggle') &&
        !searchInputContainer.contains(e.target)
      ) {
        toggleSearchInput()
      }
    }

    let searchOpen = false

    const hideDesktopSearchOnMobile = () => {
      if (searchOpen) {
        // Search is open, so we have to close it
        toggleSearchInput()
      }

      mediaChangeListener(matchMedia('(max-width: 1024px)'), hideDesktopSearchOnMobile, { once: true })
    }

    hideDesktopSearchOnMobile()

    const toggleSearchInput = () => {
      /*
       * Open desktop and sticky search
       */
      if (searchOpen) {
        searchInputContainer.classList.remove('is-active')
        searchOpen = false
        document.removeEventListener('focus', searchFocusLost, true)
        fullPageBg.removeEventListener('click', toggleSearchInput, false)
        fullPageBg.classList.add('hidden')
        cancelSearch.classList.replace('block', 'hidden')
        searchNavLink.querySelector('.desktop-open-search').classList.replace('hidden', 'block')
        searchNavLink.querySelector('.desktop-close-search').classList.replace('block', 'hidden')
        searchNavLink.setAttribute('aria-label', 'search, displays input field')
        hideOnSearchContainer.classList.replace('hidden', 'flex')

        if (stickyCancelSearch) {
          stickyCancelSearch.classList.replace('block', 'hidden')
          stickySearchNavLink.querySelector('.sticky-open-search').classList.replace('hidden', 'block')
          stickySearchNavLink.querySelector('.sticky-close-search').classList.replace('block', 'hidden')
          stickyHideOnSearchContainer.classList.replace('hidden', 'flex')
        }

        /*
         * Close desktop and sticky search
         */
      } else {
        searchInput.value = ''
        searchInputContainer.classList.add('is-active')
        searchInput.focus()
        searchOpen = true
        document.addEventListener('focus', searchFocusLost, true)
        fullPageBg.addEventListener('click', toggleSearchInput, false)
        fullPageBg.classList.remove('hidden')
        cancelSearch.classList.replace('hidden', 'block')
        searchNavLink.querySelector('.desktop-open-search').classList.replace('block', 'hidden')
        searchNavLink.querySelector('.desktop-close-search').classList.replace('hidden', 'block')
        searchNavLink.setAttribute('aria-label', 'close search')
        hideOnSearchContainer.classList.replace('flex', 'hidden')

        if (stickySearchNavLink) {
          stickyCancelSearch.classList.replace('hidden', 'block')
          stickySearchNavLink.querySelector('.sticky-open-search').classList.replace('block', 'hidden')
          stickySearchNavLink.querySelector('.sticky-close-search').classList.replace('hidden', 'block')
          stickyHideOnSearchContainer.classList.replace('flex', 'hidden')
        }
      }
    }

    navigation.querySelectorAll(['.desktop-search-toggle', '.sticky-search-toggle']).forEach(searchToggle => {
      searchToggle.addEventListener('click', () => {
        toggleSearchInput()
      })
    })

    // Watches for shift+tab when focus is inside of search input,
    // moves focus to search toggle button
    document.addEventListener('keydown', e => {
      if (document.activeElement.classList.contains('search-input') && e.shiftKey && e.key === 'Tab') {
        e.preventDefault()

        if (stickyNavContainer.classList.contains('is-sticky')) {
          navigation.querySelector('.sticky-search-toggle').focus()
        } else {
          navigation.querySelector('.desktop-search-toggle').focus()
        }
      }
    })

    const desktopClearSearchBtn = navigation.querySelector('.desktop-clear-search')

    desktopClearSearchBtn.addEventListener('click', e => {
      e.preventDefault()
      searchInput.value = ''
      searchInput.focus()
    })
  })()

  // Mobile search
  const mobileSearchBtn = navigation.querySelector('.mobile-search-btn')
  const mobileSearchContainer = navigation.querySelector('.mobile-search-container')
  const searchHiddenBtn = navigation.querySelector('.search-hidden')
  const searchVisibleBtn = navigation.querySelector('.search-visible')
  const memberDropdown = navigation.querySelector('.mobile-member-dropdown')
  const body = document.body

  const openSearch = () => {
    mobileSearchContainer.querySelector('input').value = ''
    mobileSearchContainer.classList.remove('hidden')
    setMobileMenuOpen(false)
    searchHiddenBtn.classList.replace('block', 'hidden')
    searchVisibleBtn.classList.replace('hidden', 'block')
    mobileSearchBtn.setAttribute('aria-label', 'close search')
    fullPageBg.classList.remove('hidden')
    body.classList.add('top-0')
    body.classList.add('w-full')
    body.classList.add('overflow-hidden')
    const mobileSearchInput = mobileSearchContainer.querySelector('input.lume-search')
    if (mobileSearchInput) {
      /*
       * Invoking setTimeout with a callback, and zero as the second argument
       * will schedule the callback to be run asynchronously, after the shortest
       * possible delay, which will be when the JavaScript thread of execution
       * is not busy. This gives the browser an opportunity to initialize the DOM,
       * fixing the bug.
       */
      setTimeout(() => mobileSearchInput.focus(), 0)
    }
  }

  const closeSearch = () => {
    mobileSearchContainer.classList.add('hidden')
    searchHiddenBtn.classList.replace('hidden', 'block')
    searchVisibleBtn.classList.replace('block', 'hidden')
    mobileSearchBtn.setAttribute('aria-label', 'search, displays input field')
    fullPageBg.classList.add('hidden')
    body.classList.remove('top-0')
    body.classList.remove('w-full')
    body.classList.remove('overflow-hidden')
  }

  ;(function () {
    if (mobileSearchBtn) {
      mobileSearchBtn.addEventListener('click', () => {
        if (mobileSearchContainer.classList.contains('hidden')) {
          openSearch()
        } else {
          closeSearch()
        }
      })

      memberDropdown.addEventListener('click', () => {
        if (!mobileSearchContainer.classList.contains('hidden')) {
          closeSearch()
        }
      })

      const hideSearchOnDesktop = () => {
        if (!mobileSearchContainer.classList.contains('hidden')) {
          // Search is open, so we have to close it
          closeSearch()
        }

        mediaChangeListener(matchMedia('(min-width: 1024px)'), hideSearchOnDesktop, { once: true })
      }

      hideSearchOnDesktop()
      fullPageBg.addEventListener('click', closeSearch)
    }
  })()

  /*
   * End search implementation
   */

  // Hamburger toggle
  const burger = navigation.querySelector('.hamburger')
  const mobileMenu = navigation.querySelector('#mobile-menu')
  let mobileMenuOpen = false

  const adjustMobileMenuHeight = () => {
    const height = window.innerHeight - mobileMenu.getBoundingClientRect().top
    mobileMenu.style.height = `${height}px`

    if (window.innerWidth >= 1024) {
      setMobileMenuOpen(false)
    }
  }

  const setMobileMenuOpen = open => {
    if (open) {
      mobileSearchBtn.classList.add('invisible')
      closeSearch()
      burger.classList.add('is-active')
      burger.setAttribute('aria-label', burger.dataset.closeLabel)
      burger.setAttribute('aria-expanded', true)
      fullPageBg.classList.remove('hidden')

      mobileMenu.classList.add('is-active')
      document.body.classList.add('overflow-hidden')
      adjustMobileMenuHeight()

      window.addEventListener('resize', adjustMobileMenuHeight)
    } else {
      mobileSearchBtn.classList.remove('invisible')
      burger.classList.remove('is-active')
      burger.setAttribute('aria-label', burger.dataset.openLabel)
      burger.setAttribute('aria-expanded', false)
      fullPageBg.classList.add('hidden')

      mobileMenu.classList.remove('is-active')
      document.body.classList.remove('overflow-hidden')
      window.removeEventListener('resize', adjustMobileMenuHeight)
    }

    mobileMenuOpen = open
  }

  /**
   * collapse all expanded dropdowns
   */
  const collapseAll = () => {
    mobileMenu.querySelectorAll('[aria-expanded="true"]').forEach(item => {
      item.setAttribute('aria-expanded', 'false')
      item.nextElementSibling?.classList.remove('is-active')
    })
  }

  /*
   * Close sidebar when focus leaves mobile menu
   */
  document.addEventListener('focusin', () => {
    if (
      !mobileMenuOpen ||
      document.activeElement.classList.contains('nav-icon-btn') ||
      document.activeElement.classList.contains('hodinkee-logo')
    ) {
      return
    }

    if (!mobileMenu.contains(document.activeElement)) {
      setMobileMenuOpen(false)
    }
  })

  burger.addEventListener('click', () => {
    setMobileMenuOpen(!mobileMenuOpen)
    if (!mobileMenuOpen) collapseAll()
  })

  fullPageBg.addEventListener('click', () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)
      collapseAll()
    }
  })

  memberDropdown.addEventListener('click', () => {
    if (mobileMenuOpen) {
      setMobileMenuOpen(false)
      collapseAll()
    }
  })

  // Mobile menu expandable sections
  mobileMenu.querySelectorAll('.expandable-toggle').forEach(toggle => {
    toggle.addEventListener('click', () => {
      toggle.nextElementSibling?.classList?.toggle('is-active')
      const collapse = toggle.getAttribute('aria-expanded') === 'false'
      toggle.setAttribute('aria-expanded', collapse)
    })
  })
})
