import React, { useEffect, useRef, useState } from 'react'
import HomepageFlexModule, { HomepageFlexModulePlaceholder } from './HomepageFlexModule'
import usePrevious from '../../hooks/usePrevious'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'
import i18n from 'helpers/i18n'

export default function HomepagePackageModule({ package_id, ...props }) {
  const [data, setData] = useState({})
  const [articles, setArticles] = useState([])
  const [error, setError] = useState(!package_id)
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)
  const prevProps = usePrevious({ package_id })

  useEffect(() => {
    if (visible && package_id && articles.length === 0) {
      fetchData()
    }
  }, [visible])

  useEffect(() => {
    if (articles.length > 0) {
      imgix.fluid(mainRef.current)
    }
    if (articles.length != 0 && prevProps?.package_id != package_id) {
      fetchData()
    }
  }, [articles])

  const fetchData = async () => {
    const resp = await fetch(`/api/v1/homepage/packages/${package_id}`)
    if (resp.status === 200) {
      const json = await resp.json()
      setData(json.package)
      // Package always return all of the articles that belong to it, no pagination.
      // Since the homepage only shows the first 4 articles, we handle that here.
      setArticles((json.package.cards || []).slice(0, 4))
    } else {
      setError(true)
    }
  }

  if (error) return null

  return (
    <div ref={mainRef} className="homepage-module homepage-module--flex">
      <div className="content-wrapper mobile-fullbleed">
        {articles.length > 0 ? (
          <>
            <h2>
              <span>{data.title}</span>
            </h2>
            <HomepageFlexModule
              type="package"
              package_id={package_id}
              {...props}
              title={data.title}
              articles={articles}
            />
            <a href={`/packages/${data.slug}`} className="btn">
              {i18n('home.index.view_all')}
            </a>
          </>
        ) : (
          <HomepageLatestModulePlaceholder {...props} />
        )}
      </div>
    </div>
  )
}

const HomepageLatestModulePlaceholder = ({ ...props }) => (
  <>
    <h2>
      <span className="bg-white h-20 w-2/4">
        <div className="animate-pulse bg-gray-5 h-full w-full" />
      </span>
    </h2>
    <HomepageFlexModulePlaceholder {...props} />
    <div className="btn">{i18n('home.index.view_all')}</div>
  </>
)
