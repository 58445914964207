import React from 'react'
import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const OrisTeaser = lazyComponent(
  () => import('site/components/sponsored_content/LimitedEditions/OrisTeaser'),
  <FullscreenFallback />
)

WebpackerReact.setup({ OrisTeaser })
