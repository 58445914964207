/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)
const GShockGM2100 = lazyComponent(
  () => import('site/components/sponsored_content/GShockGM2100'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  GShockGM2100: function a(props) {
    return <SponConWrapper template={GShockGM2100} {...props} />
  }
})
