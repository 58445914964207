import React, { useEffect, useRef, useState } from 'react'

import HomepageFlexModule, { HomepageFlexModulePlaceholder } from './HomepageFlexModule'
import usePrevious from '../../hooks/usePrevious'
import useIntersectionObserver from '../../hooks/useIntersectionObserver'

export default function HomepageCollectionModule({ collection_id, ...props }) {
  const [data, setData] = useState({})
  const [error, setError] = useState(!collection_id)
  const [articles, setArticles] = useState([])
  const mainRef = useRef()
  const [visible] = useIntersectionObserver(mainRef)
  const prevProps = usePrevious({ collection_id })

  useEffect(() => {
    if (visible && collection_id && articles.length === 0) {
      fetchData()
    }
  }, [visible])

  useEffect(() => {
    if (articles.length > 0) {
      imgix.fluid(mainRef.current)
    }
    if (articles.length != 0 && prevProps?.collection_id != collection_id) {
      fetchData()
    }
  }, [articles])

  const fetchData = async () => {
    const resp = await fetch(`/api/v1/homepage/collections/${collection_id}`)
    if (resp.status === 200) {
      const json = await resp.json()
      setData(json.collection)
      // Collections always return all of the articles that belong to it, no pagination.
      // Since the homepage only shows the first 4 articles, we handle that here.
      setArticles((json.collection.cards || []).slice(0, 4))
    }else{
      setError(true)
    }
  }
  
  if (error) return null

  return (
    <div ref={mainRef} className="homepage-module homepage-module--flex">
      <div className="content-wrapper mobile-fullbleed">
        {articles.length > 0 ? (
          <>
            <h2>
              <span>{data.title}</span>
            </h2>
            <HomepageFlexModule
              type="collection"
              collection_id={collection_id}
              {...props}
              title={data.title}
              articles={articles}
            />
          </>
        ) : (
          <HomepageLatestModulePlaceholder {...props} />
        )}
      </div>
    </div>
  )
}

const HomepageLatestModulePlaceholder = ({ ...props }) => (
  <>
    <h2>
      <span className="bg-white h-20 w-2/4">
        <div className="animate-pulse bg-gray-5 h-full w-full" />
      </span>
    </h2>
    <HomepageFlexModulePlaceholder {...props} />
  </>
)
