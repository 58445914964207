import React from 'react'

import ImgixHelper from 'helpers/ImgixHelper'
import Authors from './Authors'

export default function HomepageOurEditorsAuthor({ author, article }) {
  const imgixUrl = () => {
    let ix = new ImgixHelper(author.avatar_url, { w: 80, h: 80 })
    return ix.getURL()
  }

  return (
    author &&
    article && (
      <div className="author">
        <div className="author--avatar">
          <img src={imgixUrl()} alt={`Avatar of ${author.name}`} />
        </div>
        <div className="author--info">
          <p className="author--name">
            <Authors authors={[author]} />
          </p>
          <h3 className="author--article-title article-title">
            <a
              href={article.action.share_url}
              className="article-link"
            >
              <span>{article.metadata?.column_name}</span>
              {article.message.title}
            </a>
          </h3>
        </div>
      </div>
    )
  )
}

export function HomepageOurEditorsAuthorPlaceholder() {
  return (
    <div className="author animate-pulse">
      <div className="rounded-full author--avatar bg-gray-5"></div>
      <div className="author--info">
        <div className="h-4 w-1/4 bg-gray-5 mt-2 "></div>
        <div className="h-4 w-2/3 bg-gray-5 mt-2"></div>
        <div className="h-4 w-full bg-gray-5 mt-2"></div>
      </div>
    </div>
  )
}
