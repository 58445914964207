import React from 'react'

import PropTypes from 'prop-types'
import HomepageInstagramPost from './instagram/HomepageInstagramPost'
import { nt } from 'helpers/i18n'

const ariaI18n = nt('home.accessibility')

export default class HomepageInstagramModule extends React.PureComponent {
  static propTypes = {
    showTitles: PropTypes.bool,
    username: PropTypes.string
  }

  static defaultProps = {
    showTitles: true,
    username: 'hodinkee'
  }

  constructor(props) {
    super(props)

    this.state = {
      items: []
    }
  }

  componentWillMount() {
    this.fetchPosts()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.username != this.props.username) {
      this.fetchPosts()
    }
  }

  fetchPosts() {
    if (!this.props.username) return

    fetch(`/api/v1/homepage/instagram?username=${encodeURIComponent(this.props.username)}`)
      .then(resp => resp.json())
      .then(json => this.setState({ items: json.items }))
  }

  getPosts() {
    if (!this.state.items) return
    return this.state.items.slice(0, 5).map(post => <HomepageInstagramPost key={post.id} post={post} />)
  }

  render() {
    const ariaLabel = `Instagram ${this.props.username}, ${ariaI18n('new_window')}`

    return (
      <div className="homepage-module homepage-module--instagram">
        <div className="content-wrapper small-fullbleed">
          {this.props.showTitles && (
            <a className="view-all" href={`https://www.instagram.com/${this.props.username}`}
              target="_blank" aria-label={ariaLabel}>
              <svg className="icon-instagram">
                <use xlinkHref="#icon-instagram-camera" />
              </svg>
              {this.props.username}
            </a>
          )}
          {this.props.showTitles && <div className="title">Follow Along</div>}
          <div className="posts">
            <div className="scroller">{this.getPosts()}</div>
          </div>
        </div>
      </div>
    )
  }
}
