/* eslint no-console:0 */
import React from 'react'

import WebpackerReact from 'webpacker-react'
import lazyComponent from 'helpers/lazyComponent'
import FullscreenFallback from 'site/fallbacks/FullscreenFallback'

const SponConWrapper = lazyComponent(
  () => import('site/components/sponsored_content/SponConWrapper'),
  <FullscreenFallback />
)

const EpisodeOne = lazyComponent(
  () => import('site/components/sponsored_content/GSLabs/EpisodeOne'),
  <FullscreenFallback />
)
const EpisodeTwo = lazyComponent(
  () => import('site/components/sponsored_content/GSLabs/EpisodeTwo'),
  <FullscreenFallback />
)
const EpisodeThree = lazyComponent(
  () => import('site/components/sponsored_content/GSLabs/EpisodeThree'),
  <FullscreenFallback />
)
const EpisodeFour = lazyComponent(
  () => import('site/components/sponsored_content/GSLabs/EpisodeFour'),
  <FullscreenFallback />
)

WebpackerReact.setup({
  EpisodeOne,
  EpisodeTwo,
  EpisodeThree,
  EpisodeFour
})
