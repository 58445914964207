/* eslint no-console:0 */
import React from 'react'
import lazyComponent from 'helpers/lazyComponent'
import LazyFallback from 'site/fallbacks/FullscreenFallback'

const MagazineHomepage = lazyComponent(() => import('site/components/magazine/MagazineHomepage'), <LazyFallback />)

import WebpackerReact from 'webpacker-react'

WebpackerReact.setup({
  MagazineHomepage
})
